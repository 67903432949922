import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Auth from '../../../helpers/Auth';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { saveResearchActivity, fetchSingleResearchActivity } from '../../../redux/researchActivities/researchActivitiesActions';
import { getFormType, getUniqueIdToUpdate, mapDBDataWithFormData, getInitialDataFromDBMapper } from '../../../helpers/CommonHelper';
import LoadingOverlay from '../layout/LoadingOverlay';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FormSelect from 'react-bootstrap/FormSelect';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { Editor } from '@tinymce/tinymce-react';
import TinyEditorField from '../layout/TinyEditorField';

const validationSchema = Yup.object().shape({
	research_title: Yup.string().required('Research title is required'),
	description: Yup.string().required('Research description is required'),
	research_date: Yup.date().required('Research date is required'),

});

function ModifyResearchActivity() {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	let location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [readyToSubmit, setReadyToSubmit] = useState(false);
	const [clickedButton, setClickedButton] = useState("");
	const [formType, setFormType] = useState('');
	const [updateId, setUpdateId] = useState('');
	const [formFields, setFormFields] = useState({
		research_title: '',
		description: '',
		research_date: '',

		research_purpose: '',
		research_methodology: '',
		research_project_association: '',
		research_authorship_attribution: '',

		research_tool: '',
		research_tool_source: '',
		research_project_outcomes: '',
		research_data_analysis: '',
		research_interpreatation_analysis: '',
	});

	// Runs ONCE after initial rendering and after every rendering ONLY IF `location` changes
	useEffect(() => {
		performLocationChanges();
	}, [location]);

	const performLocationChanges = async () => {
		const pathName = location.pathname;
		const pathArray = pathName.split('/');
		//Set what type of form is this Insert or Update
		getFormType(pathArray).then((formType) => setFormType(formType));
		//Set update id to updateId if form is update otherwise set  ''
		getUniqueIdToUpdate(pathArray).then((updateId) => setUpdateId(updateId));
	}

	// Runs ONCE after initial rendering and after every rendering ONLY IF `formType, updateId` changes
	useEffect(() => {
		if (formType === 'UPDATE' && updateId !== '') {
			let formData = new FormData();
			formData.append('researchId', updateId);
			setIsLoading(true);
			dispatch(fetchSingleResearchActivity(formData)).then((singleData) => {
				const singleDataVal = singleData[0];
				setFormFields(singleDataVal);
				setIsLoading(false);
			});
		}
	}, [formType, updateId]);

	const handleSubmit = async (values, actions) => {
		try {
			//Removing is_deleted properties from values
			delete values.is_deleted;
			if (formType === "UPDATE" && updateId !== "") {
				values.id = updateId;
			}
			// // Handle the form submission based on the clickedButton value
			if (clickedButton === "save_progress") {
				// Save progress logic
				values.research_proposal_status = "3";
			} else if (clickedButton === "save_submit") {
				const readyToSubmit = await showSubmitDialog();
				if (!readyToSubmit) {
					return;
				}
				values.research_proposal_status = "0";
			}
			setIsLoading(true);
			const savedStatus = await dispatch(saveResearchActivity(values));
			if (savedStatus) {
				setIsLoading(false);
				navigate("/dashboard/my_research_activities");
			}
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	const showSubmitDialog = () => {
		return new Promise((resolve) => {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='confirm-dialog-container'>
							<h4 className='confirm-dialog-heading'>Are you sure to submit?</h4>
							<p className="text-danger"><strong>You cannot make further changes once you confirm the submission.</strong></p>
							<div className="full-width">
								<Button variant='info' onClick={() => {

									resolve(false); // User clicked No
									onClose();
								}}> No, I want to keep working.</Button>
								<Button variant="success" className="mx-2"
									onClick={() => {
										resolve(true); // User clicked Yes
										onClose();
									}}
								>Yes, I undertand and I want to submit.</Button>
							</div>
						</div>
					);
				},
				onClickOutside: () => {
					resolve(false);//user clicked ourside
				},
			});
		});
	}

	const isInvalid = (errors, touched, fieldName) => errors[fieldName] && touched[fieldName];
	if (!Auth.isAuthenticated()) {
		navigate("/login");
	}

	return (
		<>
			<Card className="shadow-5 m-3">
				<LoadingOverlay isLoading={isLoading} />
				<Card.Body>
					<Formik
						initialValues={formFields}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
						enableReinitialize={true}
					>
						{({ errors, touched, values, handleChange }) => (
							<>
								<Form>

									<Row>
										<Col md='6'>
											<>
												<Field name="research_title">
													{({ field }) => (
														<FloatingLabel
															controlId="floatingInput"
															label="Research title"
															className="mb-3"
														>
															<FormControl
																type="text"
																placeholder="Research title"
																{...field}
																className={isInvalid(errors, touched, 'research_title') ? 'mt-1 is-invalid' : 'mt-1'}
															/>
														</FloatingLabel>
													)}
												</Field>
												{isInvalid(errors, touched, 'research_title') && (
													<div className="invalid-feedback">{errors.research_title}</div>
												)}
											</>
											<>
												<Field name="research_date">
													{({ field }) => (
														<FloatingLabel
															controlId="floatingInput"
															label="Research date"
															className="mb-3"
														>
															<FormControl
																type="date"
																placeholder="Research date"
																{...field}
																className={isInvalid(errors, touched, 'research_date') ? 'mt-1 is-invalid' : 'mt-1'}
															/>
														</FloatingLabel>
													)}
												</Field>
												{isInvalid(errors, touched, 'research_date') && (
													<div className="invalid-feedback">{errors.research_date}</div>
												)}
											</>
										</Col>
										<Col md='6'>
											<>
												<label htmlFor="description"><strong>Research description</strong></label>
												< Field name="description" component={TinyEditorField} />
											</>
										</Col>
									</Row>
									<hr />
									<Row>
										<Col md='6'>
											<div className="mt-1">
												<label htmlFor="research_purpose"><strong>Purpose - (Why you did this research)</strong></label>
												< Field name="research_purpose" component={TinyEditorField} />
											</div>
											<div className="mt-4">
												<label htmlFor="research_project_association"><strong>Project association - (What project it was part of?)</strong></label>
												< Field name="research_project_association" component={TinyEditorField} />
											</div>
										</Col>
										<Col md='6'>
											<div className="mt-1">
												<label htmlFor="research_methodology"><strong>Methodology - (How you did this research)</strong></label>
												< Field name="research_methodology" component={TinyEditorField} />
											</div>
											<div className="mt-4">
												<label htmlFor="research_authorship_attribution"><strong>Authorship attribution - (Who thought of it, if not you?)</strong></label>
												< Field name="research_authorship_attribution" component={TinyEditorField} />
											</div>
										</Col>
									</Row>
									<hr />
									<Row>
										<Col md={6}>
											<div className="mt-1">
												<label htmlFor="research_tool"><strong>Materials used - (What materials you used?)</strong></label>
												< Field name="research_tool" component={TinyEditorField} />
											</div>
											<div className="mt-4">
												<label htmlFor="research_project_outcomes"><strong>Project outcomes - (What happened and what did not happen?)</strong></label>
												< Field name="research_project_outcomes" component={TinyEditorField} />
											</div>
											<div className="mt-4">
												<label htmlFor="research_interpreatation_analysis"><strong>Interpretation analysis - (Your interpretation (Other's interpretations if important))</strong></label>
												< Field name="research_interpreatation_analysis" component={TinyEditorField} />
											</div>
										</Col>
										<Col md={6}>
											<div className="mt-1">
												<label htmlFor="research_tool_source"><strong>Materials source - (Where did you obtained the materials?)</strong></label>
												< Field name="research_tool_source" component={TinyEditorField} />
											</div>
											<div className="mt-4">
												<label htmlFor="research_data_analysis"><strong>Data analysis - (How you manipulated and analyszed the results?)</strong></label>
												< Field name="research_data_analysis" component={TinyEditorField} />
											</div>
										</Col>
									</Row>

									<hr />
									<Row>
										<Col md='12'>
											<>
												<input type="submit" className="btn btn-primary mt-3 mx-2" name="save_progress" value="Save and continue later" onClick={e => setClickedButton(e.target.name)} />

												<input className="btn btn-success mt-3 mx-2" type="submit" name="save_submit" value="Save and submit" onClick={e => setClickedButton(e.target.name)} />
											</>
										</Col>
									</Row>
								</Form>
								{Object.keys(errors).length > 0 && (
									<div className=" mt-2 alert alert-danger">
										Please fix the validation errors before submitting the form.
									</div>
								)}
							</>
						)}
					</Formik>
				</Card.Body>
			</Card>
		</>
	)
}

export default ModifyResearchActivity;
