import axios from 'axios';
import { getAPIUrl, customAxios, rectifyAxiosErrors } from '../../services/Services';
import { extractMessageFromResponse, getToken } from '../../helpers/CommonHelper';
import * as notify from '../studentNotification/notificationAction';
const axiosInstance = customAxios();
const axiosInstanceFormData = customAxios('multipart/form-data');


export const getAllUsers = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('get_all_users');
			const response = await axiosInstance.get(callUrl);
			const responseData = response.data;
			if (responseData.status) {
				const allUsers = responseData.data;
				resolve(allUsers);
			} else {
				dispatch(notify.error({ message: "Error while getting all users list" }));
				resolve([]);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve([]);
		}
	});
};

export const saveUser = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('save_user');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const extractedMessage = await extractMessageFromResponse(responseData);
				dispatch(notify[extractedMessage.messageType]({ message: extractedMessage.message }));
				resolve(true);
			} else {
				const errorMessage = responseData.msg;
				dispatch(notify.error({ message: errorMessage }));
				resolve(false);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve(false);
		}
	});
};

export const fetchSingleUser = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('get_single_user');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const singleUserDetail = responseData.data;
				resolve(singleUserDetail);
			} else {
				dispatch(notify.error({ message: "Error while getting all users list" }));
				resolve([]);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve([]);
		}
	});
};

export const getUserPersonalDetail = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('get_user_personal_detail');
			const response = await axios.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const userPersonalDetail = responseData.user_personal_detail;
				resolve(userPersonalDetail);
			} else {
				dispatch(notify.error({ message: "Error while getting personal detail" }));
				resolve({});
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve({});
		}
	});
}


export const getUserAboutDetail = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('get_user_about_detail');
			const response = await axios.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const userAboutDetail = responseData.user_about_detail;
				resolve(userAboutDetail);
			} else {
				dispatch(notify.error({ message: "Error while getting personal detail" }));
				resolve([]);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve([]);
		}
	});
}

export const getUsersPublicResearchActivities = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('get_users_public_research_records');
			const response = await axios.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const researchRecords = responseData.research_records;
				resolve(researchRecords);
			} else {
				dispatch(notify.error({ message: "Error while getting personal detail" }));
				resolve([]);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve([]);
		}
	});
}

export const fetchUserProfile = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('get_profile_detail');
			const response = await axiosInstance.get(callUrl);
			const responseData = response.data;
			if (responseData.status) {
				const profileDetail = responseData.profile_detail;
				resolve(profileDetail);
			} else {
				dispatch(notify.error({ message: "Error while getting all users list" }));
				resolve({});
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve({});
		}
	});
};

export const updateProfile = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('update_user_profile');
			const response = await axiosInstanceFormData.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const extractedMessage = await extractMessageFromResponse(responseData);
				dispatch(notify[extractedMessage.messageType]({ message: extractedMessage.message }));
				resolve(true);
			} else {
				const errorMessage = responseData.msg;
				dispatch(notify.error({ message: errorMessage }));
				resolve(false);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve(false);
		}
	});
}

export const fetchDashboardData = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('get_dashboard_data');
			const response = await axiosInstance.get(callUrl);
			const responseData = response.data;
			if (responseData.status) {
				const dashboardData = responseData.dashboard_data;
				resolve(dashboardData);
			} else {
				dispatch(notify.error({ message: "Error while getting dashboard data" }));
				resolve([]);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve([]);
		}
	});
};