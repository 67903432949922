import React from 'react';
import { Link } from "react-router-dom";

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

function UserProfileNavigation(props) {
	return (
		<>
			<Navbar className="mt-2" bg="light" variant="light" style={{ borderRadius: '15px' }}>
				<Container fluid>
					<Nav className="me-auto">
						<Link replace={true} to="about" className='mx-2'>ABOUT</Link>
						<Link replace={true} to="research_outputs" className='mx-2'>RESEARCH OUTPUTS</Link>
					</Nav>
				</Container>
			</Navbar>
		</>
	)
}
export default UserProfileNavigation;