import { getFromLocalStorage, removeFromLocalStorage } from './CommonHelper';

class Auth {
	constructor() {
		this.authenticated = false;
		//Check if the token is available in localStorage, if token is available, then set authenticated to true
		this.isTokenAvailable();
	}

	login(cb) {
		this.authenticated = true;
		// cb();
	}

	logout(cb) {
		return new Promise((resolve, reject) => {
			this.authenticated = false;
			//Remove tokenData from localStorage
			removeFromLocalStorage('tokenData');
			removeFromLocalStorage('userSessionData');
			resolve(true);
		});
		// cb();
	}

	isAuthenticated() {
		return this.authenticated;
	}

	isTokenAvailable() {
		const tokenData = getFromLocalStorage('tokenData');
		if (tokenData) {
			try {
				const parsedTokenData = JSON.parse(tokenData);
				if (parsedTokenData.accessToken) {
					this.authenticated = true;
				}
			} catch (e) {
				this.authenticated = false;
			}
		} else {
			this.authenticated = false;
		}
	}
}

export default new Auth();
