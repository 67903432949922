import axios from 'axios';
import { getAPIUrl, getHeadersWithoutToken } from '../../services/Services';
import { updateAccessTokenInLocalStorage, getToken, logData } from '../../helpers/CommonHelper';

const newAxiosInstance = axios.create();

export const reinstateAccessToken = async () => {
	const callUrl = getAPIUrl('obtain_new_access_token');
	const axiosConfig = getHeadersWithoutToken();
	const refreshToken = getToken('refreshToken');

	const formData = new FormData();
	formData.append('refresh_token', refreshToken);

	try {
		const response = await newAxiosInstance.post(callUrl, formData, axiosConfig);
		const responseData = response.data;

		if (responseData.status) {
			const newAccessToken = responseData.access_token;
			updateAccessTokenInLocalStorage(newAccessToken);
			console.log('New access token obtained using refresh token');
			return { status: true, data: [] };
		} else {
			return { status: false, data: response };
		}
	} catch (error) {
		return { status: false, data: error };
	}
};

