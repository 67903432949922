import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Alert } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TinyEditorField from '../../layout/TinyEditorField';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { saveFinalReportFeedback, exploreSingleResearchDetail } from '../../../../redux/researchActivities/researchActivitiesActions';
import FinalReportView from '../FinalReportView';
import Auth from '../../../../helpers/Auth';

const validationSchema = Yup.object().shape({
	final_report_feedback: Yup.string().required('Feedback is required'),
});

function EvaluatorFinalReport(props) {
	const dispatch = useDispatch();
	const [researchId, setResearchId] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [clickedButton, setClickedButton] = useState('');
	const [formFields, setFormFields] = useState({
		final_report_feedback: '',
	});

	const allowModification = useSelector(state => state.researchActivities.modificationFlags.evaluatorFinalReportModification); //Same as mapStateToProps
	const researchDetail = useSelector(state => state.researchActivities.exploreSingleResearchDetail); //Same as mapStateToProps

	useEffect(() => {
		setResearchId(props.research_id);
	}, [props]);

	useEffect(() => {
		if ('id' in researchDetail && (researchDetail.final_report_status === '1' || researchDetail.final_report_status === '2')) {
			setFormFields({ final_report_feedback: researchDetail.final_report_feedback });
		}
	}, [researchDetail]);

	const obtainSingleRecordFromDB = () => {
		const postData = {
			researchId: researchId,
		};
		dispatch(exploreSingleResearchDetail(postData));
	};

	const handleSubmit = async (values) => {
		try {
			let postData = {
				id: researchId,
				final_report_feedback: values.final_report_feedback,
			};
			if (clickedButton === 'save_progress') {
				postData.final_report_status = '2';
			} else if (clickedButton === 'save_submit') {
				const readyToSubmit = await showSubmitDialog();
				if (!readyToSubmit) {
					return;
				}
				postData.final_report_status = '3';
			}
			const savedStatus = await dispatch(saveFinalReportFeedback(postData));
			if (savedStatus) {
				obtainSingleRecordFromDB();
			}
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	const showSubmitDialog = () => {
		return new Promise((resolve) => {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className="confirm-dialog-container">
							<h4 className="confirm-dialog-heading">Are you sure to submit?</h4>
							<p className="text-danger">
								<strong>You cannot make further changes once you confirm.</strong>
							</p>
							<div className="full-width">
								<Button
									variant="info"
									onClick={() => {
										resolve(false);
										onClose();
									}}
								>
									No, I want to keep working.
								</Button>
								<Button
									variant="success"
									className="mx-2"
									onClick={() => {
										resolve(true);
										onClose();
									}}
								>
									Yes, I understand and I want to confirm.
								</Button>
							</div>
						</div>
					);
				},
				onClickOutside: () => {
					resolve(false);
				},
			});
		});
	};

	const isInvalid = (errors, touched, fieldName) => errors[fieldName] && touched[fieldName];

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />;
	}

	return (
		<>
			{allowModification && researchDetail.final_report_status !== '3' ? (
				<Card className="mt-3">
					<Card.Body className="custom-card-body">
						<Row>
							<Formik
								initialValues={formFields}
								validationSchema={validationSchema}
								onSubmit={handleSubmit}
								enableReinitialize={true}
							>
								{({ errors, touched, values }) => (
									<Form>
										<Col md={12}>
											<div className="mt-1">
												<label htmlFor="final_report_feedback">
													<strong>Provide your final report feedback</strong>
												</label>
												<Field name="final_report_feedback" component={TinyEditorField} />
											</div>
										</Col>
										<Col md={12}>
											<input
												type="submit"
												className="btn btn-primary mt-3 mx-2"
												name="save_progress"
												value="Save and continue later"
												onClick={(e) => setClickedButton(e.target.name)}
											/>
											<input
												type="submit"
												className="btn btn-success mt-3 mx-2"
												name="save_submit"
												value="Submit feedback"
												onClick={(e) => setClickedButton(e.target.name)}
											/>
										</Col>
										{Object.keys(errors).length > 0 && (
											<div className=" mt-2 alert alert-danger">
												Please fix the validation errors before submission.
											</div>
										)}
									</Form>
								)}
							</Formik>
						</Row>
					</Card.Body>
				</Card>
			) : (
				<Card className="mt-3">
					<Card.Body className="custom-card-body">
						<Alert variant="primary">
							<strong>Note:</strong>
							<br />
							<span>
								Feedback cannot be provided for this research activity.	</span>
						</Alert>
					</Card.Body>
				</Card>
			)}
			<FinalReportView research_detail={researchDetail} />
		</>
	);
}

export default EvaluatorFinalReport;
