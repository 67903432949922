import React from 'react';
import { Card } from 'react-bootstrap';
import parse from 'html-react-parser';

const ResearchCard = React.forwardRef((props, ref) => {
	return (
		<Card className="mt-3" ref={ref}>
			<Card.Body>
				<h5>{props.title}</h5>
				<hr />
				<div>{props.body ? parse(props.body) : ''}</div>
			</Card.Body>
		</Card>
	);
});

export default ResearchCard;
