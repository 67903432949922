import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

import { composeWithDevTools } from '@redux-devtools/extension';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';


import { AES, enc } from 'crypto-js';

const encryptionEnabled = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION === 'true';
const encryptionSecretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
const persistConfig = {
	key: 'root',
	storage,
	serialize: (state) => {
		const stateToPersist = { ...state };
		// Exclude or manipulate specific data before persisting
		delete stateToPersist.dataToExclude;
		// Serialize the modified state
		const originalString = JSON.stringify(stateToPersist);
		if (encryptionEnabled) {
			// Encrypt the string
			const encryptedString = AES.encrypt(originalString, encryptionSecretKey).toString();
			return encryptedString;
		} else {
			return originalString;
		}

	},
	deserialize: (serializedState) => {
		const serializedString = serializedState;
		// Deserialize the state
		if (encryptionEnabled) {
			// Decrypt the string
			const decryptedBytes = AES.decrypt(serializedString, encryptionSecretKey);
			const decryptedString = decryptedBytes.toString(enc.Utf8);
			// Manipulate or process the deserialized state if needed
			// Return the deserialized state
			return JSON.parse(decryptedString);
		} else {
			return JSON.parse(serializedString);
		}
	},
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const devToolsEnabled = process.env.REACT_APP_ENABLE_REDUX_DEV_TOOL === 'true';

// const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
// const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
let store;
if (devToolsEnabled) {
	// Use Redux DevTools extension if devToolsEnabled is true
	const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
	store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
} else {
	store = createStore(persistedReducer, applyMiddleware(thunk));
}


const persistor = persistStore(store)
export { store, persistor }
