import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as notify from '../../../../redux/studentNotification/notificationAction';
import Auth from '../../../../helpers/Auth';
import { saveProposalEvaluation, exploreSingleResearchDetail } from '../../../../redux/researchActivities/researchActivitiesActions';
import ProposalFeedbackView from '../ProposalFeedbackView';

function EvaluatorProposalFeedback(props) {
	const dispatch = useDispatch();
	const [researchId, setResearchId] = useState('');

	const [proposalStatus, setProposalStatus] = useState('0');
	const [proposalFeedback, setProposalFeedback] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [allowEvaluation, setAllowEvaluation] = useState(false);

	const researchDetail = useSelector(state => state.researchActivities.exploreSingleResearchDetail); //Same as mapStateToProps

	useEffect(() => {
		setResearchId(props.research_id);
	}, [props]);

	useEffect(() => {
		if (researchDetail && Object.keys(researchDetail).length !== 0) {
			setProposalStatus(researchDetail.research_proposal_status);
			setProposalFeedback(researchDetail.research_proposal_feedback);
			setAllowEvaluation(researchDetail.research_proposal_status === '0');
		}
	}, [researchDetail]);

	const obtainResearchDataFromDB = () => {
		const postData = {
			researchId: researchId,
		};
		dispatch(exploreSingleResearchDetail(postData));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			if (proposalStatus === '0') {
				dispatch(notify.error({ message: 'You must either accept or reject the proposal to confirm the evaluation.' }));
				return;
			}
			if (!proposalFeedback || proposalFeedback === '') {
				dispatch(notify.error({ message: 'You must provide some feedback for this evaluation.' }));
				return;
			}
			const readyToSubmit = await showSubmitDialog();
			if (!readyToSubmit) {
				return;
			}
			const postData = {
				id: researchDetail.id,
				research_proposal_status: proposalStatus,
				research_proposal_feedback: proposalFeedback,
			};
			const savedStatus = await dispatch(saveProposalEvaluation(postData));
			if (savedStatus) {
				obtainResearchDataFromDB();
			}
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	const showSubmitDialog = () => {
		return new Promise((resolve) => {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className="confirm-dialog-container">
							<h4 className="confirm-dialog-heading">Are you sure to confirm evaluation?</h4>
							<p className="text-danger"><strong>You cannot make further changes once you confirm the evaluation.</strong></p>
							<div className="full-width">
								<Button variant="info" onClick={() => {
									resolve(false); // User clicked No
									onClose();
								}}>
									No, I want to evaluate later.
								</Button>
								<Button variant="success" className="mx-2" onClick={() => {
									resolve(true); // User clicked Yes
									onClose();
								}}>
									Yes, I understand and I want to confirm.
								</Button>
							</div>
						</div>
					);
				},
				onClickOutside: () => {
					resolve(false); // User clicked outside
				},
			});
		});
	};

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />;
	}

	return (
		<>
			{allowEvaluation ? (
				<Card className="mt-3">
					<Card.Header className="custom-card-header">
						<strong>Proposal evaluation</strong>
						{!allowEvaluation && <i>This proposal is already evaluated.</i>}
					</Card.Header>
					<Card.Body className="custom-card-body">
						<Row>
							<Col md={6}>
								<div>
									<InputGroup>
										<InputGroup.Text><strong>What do you want to do with this research proposal?</strong></InputGroup.Text>
										<Form.Select
											name="is_publicly_searchable"
											aria-label="Default select example"
											onChange={(e) => {
												setProposalStatus(e.target.value);
											}}
											value={proposalStatus}
											disabled={!allowEvaluation}
										>
											<option value="0">Select an option</option>
											<option value="1">Accept</option>
											<option value="2">Reject</option>
										</Form.Select>
									</InputGroup>
								</div>
							</Col>
							<Col md={12}>
								<div className="mt-2">
									<InputGroup>
										<InputGroup.Text><strong>Provide reason</strong></InputGroup.Text>
										<Form.Control
											as="textarea"
											aria-label="Provide reason"
											defaultValue={proposalFeedback || ""}
											onChange={(e) => setProposalFeedback(e.target.value)}
											disabled={!allowEvaluation}
										/>
									</InputGroup>
								</div>
							</Col>
							{allowEvaluation && (
								<Col md={12}>
									<Button className="mt-3" type="submit" variant="success" onClick={handleSubmit}>
										Confirm evaluation
									</Button>
								</Col>
							)}
						</Row>
					</Card.Body>
				</Card>
			) : (
				<ProposalFeedbackView research_detail={researchDetail} research_id={researchId} />
			)}
		</>
	);
}

export default EvaluatorProposalFeedback;
