import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket, faGraduationCap } from "@fortawesome/free-solid-svg-icons";

function TopNavigation(props) {
	return (
		<>
			<Navbar style={{ backgroundColor: '#4c566a' }}>
				<Container fluid>
					<Navbar.Brand>
						<Link replace={true} to="/">
							<img
								alt=""
								src={process.env.PUBLIC_URL + '/images/logo_transparent.png'}
								width="175"
								height="75"
								className="d-inline-block align-top"
							/>{''}
						</Link>
					</Navbar.Brand>
					<Navbar.Collapse className="justify-content-end">
						<Nav className="">
							<Nav className='mx-2 frontend-topbar' style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
								<Link replace={true} to="login">
									<FontAwesomeIcon icon={faRightToBracket} /> Member login
								</Link>
							</Nav>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
}

export default TopNavigation;