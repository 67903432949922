import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Auth from '../../helpers/Auth';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { performLogin } from '../../redux/login/loginAction';
import * as notify from '../../redux/studentNotification/notificationAction';


function Login() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const [userName, setUserName] = useState('');
	const [password, setPassWord] = useState('');

	const authChecked = useRef(false); //using useRef to call only once from useeffect, workaround is to remove strictmode from index.js

	useEffect(() => {
		if (authChecked.current) return;
		authChecked.current = true;
		if (Auth.isAuthenticated()) {
			setTimeout(() => {
				navigate("/dashboard", { replace: true });
			}, 100); // Add a slight delay before navigating
		}
	}, []);

	async function handleSubmit(e) {
		e.preventDefault();
		if (!userName || !password || userName === '' || password === '') {
			dispatch(notify.error({ message: 'Username and Password are required' }));
			return;
		}
		const formData = new FormData();
		formData.append('username', userName.trim());
		formData.append('password', password.trim());
		setIsLoading(true);
		dispatch(performLogin(formData)).then((loginSuccess) => {
			if (loginSuccess) {
				setTimeout(() => {
					navigate("/dashboard", { replace: true });
				}, 500); // Add a slight delay before navigating
			}
			setIsLoading(false);
		});

	}

	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				toastClassName="dark-toast"
			/>
			<Container fluid className="login-container-wrapper">
				<Row>
					<Col md={{ span: 4, offset: 4 }} className="mt-4">
						<Card className="login-card-background">
							<Card.Body>
								<Row>
									<Col md='12'>
										<div className="text-center">
											<img src={process.env.PUBLIC_URL + '/images/logo_transparent.png'} style={{ width: '300px' }} alt="logo" />
											<h4 className="mt-1 mb-5 pb-1">Record of Research Activity (RRA) Portal</h4>
										</div>
									</Col>
									<Col md={12}>
										<form className="login-form-wrapper" onSubmit={handleSubmit} autoComplete="off">
											<FloatingLabel
												controlId="email_address"
												label="Email address or username"
												className="mb-4"
											>
												<FormControl type="text" placeholder="Email address or username" value={userName} onChange={e => setUserName(e.target.value)} />
											</FloatingLabel>
											<FloatingLabel
												controlId="password"
												label="Password"
												className="mb-4"
											>
												<FormControl type="password" placeholder="Password" value={password} onChange={e => setPassWord(e.target.value)} />
											</FloatingLabel>

											<Button variant="primary" className="mb-4 w-100 gradient-custom-2" type="submit" disabled={isLoading}>
												{isLoading ? 'Loading…' : 'Sign in'}
											</Button>
										</form>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row >
			</Container >

		</>
	);
}

export default Login;