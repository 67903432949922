import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import tinymceConfig from '../../../config/TinymceConfig.json';

function TinyEditorField(props) {
	const {
		label,
		field: { name, value },
		form: { errors, touched, setFieldValue }
	} = props;

	const handleChange = (content, editor) => {
		setFieldValue(name, content);
	};

	return (
		<>
			<label htmlFor={name}>{label}</label>
			<Editor
				textareaName={name}
				apiKey='n38ezoawqpjvf11pwcln04k65wwqelfkdns2ods90hbbq4bv'
				value={value}
				onEditorChange={handleChange}
				init={tinymceConfig.tinyMcePartialConfig}
			/>
			{errors[name] && touched[name] && (
				<div className="invalid-feedback mt-2">{errors[name]}</div>
			)}
		</>
	);
}

export default TinyEditorField;
