import axios from 'axios';
import { getAPIUrl, customAxios, rectifyAxiosErrors } from '../../services/Services';
import { extractMessageFromResponse } from '../../helpers/CommonHelper';
import * as notify from '../studentNotification/notificationAction';

import * as researchActivityHelpers from './researchActivitiesActionHelpers';

const axiosInstance = customAxios();

export const getAllResearchActivities = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('get_user_research_records');
			const response = await axiosInstance.get(callUrl);
			const responseData = response.data;
			if (responseData.status) {
				const allUsers = responseData.data;
				resolve(allUsers);
			} else {
				dispatch(notify.error({ message: "Error while getting all research records" }));
				resolve([]);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve([]);
		}
	});
};

export const saveResearchActivity = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('save_research_record');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const extractedMessage = await extractMessageFromResponse(responseData);
				dispatch(notify[extractedMessage.messageType]({ message: extractedMessage.message }));
				resolve(true);
			} else {
				const errorMessage = responseData.msg;
				dispatch(notify.error({ message: errorMessage }));
				resolve(false);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve(false);
		}
	});
};

export const fetchSingleResearchActivity = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			dispatch(researchActivityHelpers.singleResearchActivityFetchRequest());
			const callUrl = getAPIUrl('get_single_user_research_record');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const singleUserDetail = responseData.data;
				dispatch(researchActivityHelpers.singleResearchActivityFetchSuccess(singleUserDetail));
				resolve(singleUserDetail);
			} else {
				const errorMessage = "Error while getting all users list";
				dispatch(notify.error({ message: errorMessage }));
				dispatch(researchActivityHelpers.singleResearchActivityFetchFailure(errorMessage));
				resolve([]);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			dispatch(researchActivityHelpers.singleResearchActivityFetchFailure(allErrorMessages));
			resolve([]);
		}
	});
};

export const getSingleResearchDetailForPublic = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('get_single_public_research_record');
			const response = await axios.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const researchRecord = responseData.research_record;
				resolve(researchRecord);
			} else {
				dispatch(notify.error({ message: "Error while getting personal detail" }));
				resolve({});
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve({});
		}
	});
}

export const getResearchActivitiesToExplore = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('get_research_records_to_explore');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const allRecords = responseData.records_to_explore;
				resolve(allRecords);
			} else {
				dispatch(notify.error({ message: "Error while getting all research records" }));
				resolve([]);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve([]);
		}
	});
};

export const exploreSingleResearchDetail = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			dispatch(researchActivityHelpers.exploreSingleResearchActivityFetchRequest());
			const callUrl = getAPIUrl('explore_single_research');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const researchRecord = responseData.research_record;
				dispatch(researchActivityHelpers.exploreSingleResearchActivityFetchSuccess(researchRecord));
				resolve(researchRecord);
			} else {
				const errorMessage = "Error while getting single research detail";
				dispatch(notify.error({ message: errorMessage }));
				dispatch(researchActivityHelpers.exploreSingleResearchActivityFetchFailure(errorMessage));
				resolve({});
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			dispatch(researchActivityHelpers.exploreSingleResearchActivityFetchFailure(allErrorMessages));
			resolve({});
		}
	});
}

export const saveProposalEvaluation = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('evaluate_proposal');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const extractedMessage = await extractMessageFromResponse(responseData);
				dispatch(notify[extractedMessage.messageType]({ message: extractedMessage.message }));
				resolve(true);
			} else {
				const errorMessage = responseData.msg;
				dispatch(notify.error({ message: errorMessage }));
				resolve(false);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve(false);
		}
	});
};

export const getProgressReports = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			dispatch(researchActivityHelpers.researchProgressReportsFetchRequest());
			const callUrl = getAPIUrl('get_progress_reports');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const progressReports = responseData.progress_reports;
				dispatch(researchActivityHelpers.researchProgressReportsFetchSuccess(progressReports));
				resolve(progressReports);
			} else {
				const errorMessage = "Error while getting single research detail";
				dispatch(notify.error({ message: errorMessage }));
				dispatch(researchActivityHelpers.researchProgressReportsFetchFailure(errorMessage));
				resolve([]);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			dispatch(researchActivityHelpers.researchProgressReportsFetchFailure(allErrorMessages));
			resolve([]);
		}
	});
}

export const saveProgressReport = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('save_progress_report');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const extractedMessage = await extractMessageFromResponse(responseData);
				dispatch(notify[extractedMessage.messageType]({ message: extractedMessage.message }));
				resolve(true);
			} else {
				const errorMessage = responseData.msg;
				dispatch(notify.error({ message: errorMessage }));
				resolve(false);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve(false);
		}
	});
};

export const saveFinalReport = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('save_final_report');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const extractedMessage = await extractMessageFromResponse(responseData);
				dispatch(notify[extractedMessage.messageType]({ message: extractedMessage.message }));
				resolve(true);
			} else {
				const errorMessage = responseData.msg;
				dispatch(notify.error({ message: errorMessage }));
				resolve(false);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve(false);
		}
	});
};

export const saveFinalReportFeedback = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('save_final_report_feedback');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const extractedMessage = await extractMessageFromResponse(responseData);
				dispatch(notify[extractedMessage.messageType]({ message: extractedMessage.message }));
				resolve(true);
			} else {
				const errorMessage = responseData.msg;
				dispatch(notify.error({ message: errorMessage }));
				resolve(false);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve(false);
		}
	});
};

export const changePublicAvailablityFlag = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('change_public_availability');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const extractedMessage = await extractMessageFromResponse(responseData);
				dispatch(notify[extractedMessage.messageType]({ message: extractedMessage.message }));
				resolve(true);
			} else {
				const errorMessage = responseData.msg;
				dispatch(notify.error({ message: errorMessage }));
				resolve(false);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve(false);
		}
	});
};