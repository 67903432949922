export const SINGLE_RESEARCH_ACTIVITY_FETCH_REQUEST = 'SINGLE_RESEARCH_ACTIVITY_FETCH_REQUEST'
export const SINGLE_RESEARCH_ACTIVITY_FETCH_SUCCESS = 'SINGLE_RESEARCH_ACTIVITY_FETCH_SUCCESS'
export const SINGLE_RESEARCH_ACTIVITY_FETCH_FAILURE = 'SINGLE_RESEARCH_ACTIVITY_FETCH_FAILURE'

export const EXPLORE_SINGLE_RESEARCH_FETCH_REQUEST = 'EXPLORE_SINGLE_RESEARCH_FETCH_REQUEST'
export const EXPLORE_SINGLE_RESEARCH_FETCH_SUCCESS = 'EXPLORE_SINGLE_RESEARCH_FETCH_SUCCESS'
export const EXPLORE_SINGLE_RESEARCH_FETCH_FAILURE = 'EXPLORE_SINGLE_RESEARCH_FETCH_FAILURE'

export const PROGRESS_REPORTS_FETCH_REQUEST = 'PROGRESS_REPORTS_FETCH_REQUEST'
export const PROGRESS_REPORTS_FETCH_SUCCESS = 'PROGRESS_REPORTS_FETCH_SUCCESS'
export const PROGRESS_REPORTS_FETCH_FAILURE = 'PROGRESS_REPORTS_FETCH_FAILURE'

export const SET_RESEARCH_MODIFICATION_FLAGS = 'SET_RESEARCH_MODIFICATION_FLAGS'