import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import Auth from '../../../helpers/Auth';
import LoadingOverlay from '../layout/LoadingOverlay';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faFilterCircleXmark, faCircleExclamation, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import DataTable from 'react-data-table-component';
import dataTableStyleConfig from '../../../config/DataTablesConfig.json';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { getAllUsers, saveUser } from './../../../redux/userManagement/userManagementActions';
import { getUserStatusFlag } from '../../../helpers/CommonHelper';

const UserListing = () => {
	const dispatch = useDispatch();
	const dataFetchedRef = useRef(false); //using useRef to call only once from useeffect, workaround is to remove strictmode from index.js
	const [isLoading, setIsLoading] = useState(false);
	let [usersList, setUsersList] = useState([]);
	const [filterText, setFilterText] = React.useState('');
	//Filtering and pagination - STARTS
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const [tableColumns, setTableColumns] = useState([
		{
			name: 'Full name',
			selector: row => row.first_name + ' ' + row.middle_name + ' ' + row.last_name,
			sortable: true,
		},
		{
			name: 'Hierarchy',
			selector: row => row.hierarchy_name + ' - Level: ' + row.level,
			sortable: true,
		},
		{
			name: 'Email',
			selector: row => row.email,
			sortable: true,
		},
		{
			name: 'Username',
			selector: row => row.username,
			sortable: true,
		},
		{
			name: 'Status',
			selector: row => row.statusText,
			sortable: true,
		},
		{
			name: 'Action',
			sortable: false,
			cell: (row) =>
				<>
					<Link replace={true} to={`/dashboard/user_management/update/${row.id}`} title="Edit Record">
						<Button size="sm" variant='info'><FontAwesomeIcon icon={faPenToSquare} /></Button>
					</Link>
					<Button size="sm" className="mx-1" variant='danger' onClick={() => handleRowDelete(row.id, row.first_name)}><FontAwesomeIcon icon={faTrash} /></Button>
				</>,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	]);
	const filteredItems = usersList.filter(
		item =>
			(item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase())) ||
			(item.middle_name && item.middle_name.toLowerCase().includes(filterText.toLowerCase())) ||
			(item.last_name && item.last_name.toLowerCase().includes(filterText.toLowerCase()))
	);
	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<>
				<Form.Group controlId="table_search">
					<Form.Control type="text" placeholder="Search..."
						value={filterText || ""}
						onChange={e => setFilterText(e.target.value)} />
				</Form.Group>
				<Button size title="Reset filters" onClick={handleClear} variant='outline-secondary'><FontAwesomeIcon icon={faFilterCircleXmark} /></Button >
			</>
		);
	}, [filterText, resetPaginationToggle]);
	const handleRowDelete = (userId, userFirstName) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='confirm-dialog-container'>
						<h4 className='confirm-dialog-heading'>Are you sure to delete?</h4>
						<p className="confirm-dialog-p">{userFirstName}</p>
						<div className="full-width">
							<Button variant='info' onClick={onClose}><FontAwesomeIcon icon={faCircleExclamation} /> No, I do not want to delete</Button>
							<Button variant="success" className="mx-2"
								onClick={() => {
									confirmDelete(userId);
									onClose();
								}}
							>
								<FontAwesomeIcon icon={faCircleCheck} /> Yes, delete it!
							</Button>
						</div>
					</div>
				);
			}
		});
	};
	const confirmDelete = (userId) => {
		if (!userId || userId === 0) return;

		//Perform Delete by changing the is_deleted flag
		let toModifyData = {};
		toModifyData.is_deleted = '1';
		toModifyData.id = userId;
		dispatch(saveUser(toModifyData)).then(() => {
			setIsLoading(true);
			dispatch(getAllUsers()).then((allUsers) => {
				changeDataValues(allUsers);
				setIsLoading(false);
			});
		});
	}
	//Filtering and pagination - ENDS

	// Runs ONCE after initial rendering
	useEffect(() => {
		if (dataFetchedRef.current) return;
		setIsLoading(true);
		dataFetchedRef.current = true;
		dispatch(getAllUsers()).then((allUsers) => {
			changeDataValues(allUsers);
			setIsLoading(false);
		});
	}, []);

	const changeDataValues = async (data) => {
		let dataToModify = [...data];
		dataToModify.forEach(async (singleRow) => {
			singleRow.statusText = await getUserStatusFlag(singleRow.status);
		});
		setUsersList(dataToModify);
	};


	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}

	return (
		<div>
			<Card className="shadow-5">
				<Card.Body>
					<LoadingOverlay isLoading={isLoading} />
					{
						(() => {
							if (!usersList || usersList.length <= 0) {
								return (
									<div>
										<h5>No records currently available</h5>
									</div>
								)
							} else {
								return (
									<>
										<DataTable
											title="List of users"
											pagination
											paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
											columns={tableColumns}
											data={filteredItems}
											fixedHeader
											subHeader
											subHeaderComponent={subHeaderComponentMemo}
											customStyles={dataTableStyleConfig.backendDataTableStyleConfig}
										/>
									</>
								)
							}
						})()
					}

				</Card.Body>
			</Card>
		</div>
	)
}

export default UserListing;