
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Auth from '../../../helpers/Auth';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import DashboardCard from './DashboardCard';
import LoadingOverlay from '../layout/LoadingOverlay';

import { fetchDashboardData } from '../../../redux/userManagement/userManagementActions';

const Dashboard = () => {

	const dispatch = useDispatch();
	const dataFetchedRef = useRef(false); //using useRef to call only once from useeffect, workaround is to remove strictmode from index.js
	let navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [dashboardData, setDashboardData] = useState([]);

	//Run on initial render only once
	useEffect(() => {
		if (dataFetchedRef.current) return;
		setIsLoading(true);
		dataFetchedRef.current = true;
		dispatch(fetchDashboardData()).then((obtainedDashboardData) => {
			setDashboardData(obtainedDashboardData);
			setIsLoading(false);
		});
	}, []);

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}


	return (
		<>
			<Card className="shadow-5 ">
				<LoadingOverlay isLoading={isLoading} />
				<Card.Body>
					<Card.Title>Dashboard</Card.Title>
					<hr />
					<Container fluid>
						<Row>
							<Col md={3}>
								<DashboardCard heading={dashboardData.total_research_records} description="Total number of research activities" />
							</Col>
						</Row>
					</Container>

				</Card.Body>
			</Card>
		</>
	)
}

export default Dashboard;