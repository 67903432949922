export const capitalizeFirstLetter = (string) => {
	if (string !== '') {
		return string.charAt(0).toUpperCase() + string.slice(1);
	} else {
		return "N/A";
	}
}

export const logData = (data) => {
	//ToDo
	//Restrict all console.log in production mode
	if (process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION') {
		// console.log('called by ' + logData.caller);
		console.log(data);
	}
}

export const displayFormData = (formData) => {
	// Display the key/value pairs
	for (var pair of formData.entries()) {
		console.log(pair[0] + ', ' + pair[1]);
	}
}

export const saveToLocalStorage = (key, value) => {
	localStorage.setItem(key, value);
}

export const getFromLocalStorage = (key) => {
	// return id to cancel
	// const intervalID = setInterval(getWeightFromLS, 3000)
	return localStorage.getItem(key);
	// return setInterval(localStorage.getItem(key), 3000)
}

export const removeFromLocalStorage = (key) => {
	return localStorage.removeItem(key);
}

export const getToken = (type) => {
	const tokenData = getFromLocalStorage('tokenData');
	let token = "NA";
	if (tokenData) {
		const parsedTokenData = JSON.parse(tokenData);
		token = parsedTokenData[type] || token;
	}
	return token;
}

export const getSessionData = (type) => {
	const userSessionData = getFromLocalStorage('userSessionData');
	let toReturn = "";
	if (userSessionData) {
		const parsedUserSessionData = JSON.parse(userSessionData);
		toReturn = parsedUserSessionData[type] || toReturn;
	}
	return toReturn;
}

export const updateAccessTokenInLocalStorage = (newAccessToken) => {
	const currentRefreshToken = getToken('refreshToken');
	const newTokenData = {
		'accessToken': newAccessToken,
		'refreshToken': currentRefreshToken,
	}
	removeFromLocalStorage('tokenData');
	saveToLocalStorage('tokenData', JSON.stringify(newTokenData));
}

export const updateSessionDataInLocalStorage = (type, dataToUpdate) => {
	const userSessionData = JSON.parse(localStorage.getItem('userSessionData')) || {};
	userSessionData[type] = dataToUpdate;
	removeFromLocalStorage('userSessionData');
	saveToLocalStorage('userSessionData', JSON.stringify(userSessionData));
	return true;
};

export const getResultFromStoreState = (globalState, internalState) => {
	let toReturnResult;
	if (!globalState.hasOwnProperty(internalState)) {
		return {};
	}
	toReturnResult = globalState[internalState].result;
	return toReturnResult;
}

export const getFormType = async (locationArray) => {
	return new Promise((resolve, reject) => {
		if (locationArray.length <= 0) {
			resolve('NONE');
		}
		else if (locationArray.includes('new')) {
			resolve('INSERT');
		}
		else if (locationArray.includes('update')) {
			resolve('UPDATE');
		} else {
			resolve('NONE');
		}
	});
}

export const getUniqueIdToUpdate = async (locationArray) => {
	return new Promise(async (resolve, reject) => {
		const newArr = [...locationArray];
		const formType = await getFormType(newArr);
		if (formType === 'NONE' || formType === 'INSERT') {
			resolve('');
		} else if (formType === 'UPDATE') {
			resolve(locationArray.pop());
		} else {
			resolve('');
		}
	});
}

export const mapDBDataWithFormData = async (dbData, dbMapper) => {
	return new Promise(async (resolve, reject) => {
		if (dbData.length <= 0 || dbMapper.length <= 0) {
			resolve({});
		}

		let toReturn = {};
		for (const dbKey in dbMapper) {
			const thisFormKey = dbMapper[dbKey];
			toReturn[thisFormKey] = dbData[dbKey];
		}

		resolve(toReturn);
	});
}

export const getInitialDataFromDBMapper = async (dbMapper) => {
	return new Promise(async (resolve, reject) => {
		if (dbMapper.length <= 0) {
			resolve({});
		}

		let toReturn = {};
		for (let dbKey in dbMapper) {
			let thisFormKey = dbMapper[dbKey];
			toReturn[thisFormKey] = '';
		}

		resolve(toReturn);
	});
}

export const extractMessageFromResponse = async (responseData) => {
	return new Promise(async (resolve, reject) => {
		if (!responseData || responseData.length === 0 || !responseData.messages) {
			resolve({
				'messageType': 'error',
				'message': 'Invalid responseData'
			});
		}

		let responseMessages = responseData.messages;
		if (responseMessages.success) {
			resolve({
				'messageType': 'success',
				'message': responseMessages.success
			});
		} else if (responseMessages.error) {
			resolve({
				'messageType': 'error',
				'message': responseMessages.error
			});
		}

		resolve({
			'messageType': 'error',
			'message': 'Unexpected error. Contact IT'
		});
	});
}

export const getUserStatusFlag = async (userStatus) => {
	return new Promise(async (resolve, reject) => {
		if (userStatus === '0') {
			resolve('Inactive');
		} else if (userStatus === '1') {
			resolve('Active');
		}
	});
}

export const getProposalStatusText = (researchProposalStatus) => {
	let researchProposalStatusText = 'NA';
	switch (researchProposalStatus) {
		case '0':
			researchProposalStatusText = 'Submitted';
			break;
		case '1':
			researchProposalStatusText = 'Accepted';
			break;
		case '2':
			researchProposalStatusText = 'Rejected';
			break;
		case '3':
			researchProposalStatusText = 'Proposal Saved';
			break;
		default:
			researchProposalStatusText = 'Flag not defined';
			break;

	}
	return researchProposalStatusText;
}

export const getModificationFlags = (proposalStatus, finalReportStatus, numOfProgressReports, numOfSubmittedProgressReports) => {
	let toReturnStatus = {
		researcherProgressReportModification: false,
		researcherFinalReportModification: false,
		evaluatorFinalReportModification: false,
	}

	if (proposalStatus === '' || !finalReportStatus === '') {
		return false;
	}

	//For progress report
	//Allow change only if the proposal is accepted
	if (proposalStatus === '1' && finalReportStatus === '0') {
		// setAllowProgressReportModification(true);
		toReturnStatus.researcherProgressReportModification = true;
	} else {
		// setAllowProgressReportModification(false);
		toReturnStatus.researcherProgressReportModification = false;

	}

	//For - final_report

	//For Researcher
	//Allow change only 
	//if the proposal is accepted and 
	//there is at lease one progress report and
	//all available progress reports are submitted and
	//the final_report is not submitted and 
	if (proposalStatus === '1' && numOfProgressReports >= 1 && numOfProgressReports === numOfSubmittedProgressReports && finalReportStatus === '0') {
		// setAllowFinalReportModification(true);
		toReturnStatus.researcherFinalReportModification = true;

	} else {
		// setAllowFinalReportModification(false);
		toReturnStatus.researcherFinalReportModification = false;

	}

	//For evaluator
	//Allow change only of the final report is submitted or the evaluation is saved by evaluator
	if (finalReportStatus === '1' || finalReportStatus === '2') {
		// setAllowEvaluatorFinalReportModification(true);
		toReturnStatus.evaluatorFinalReportModification = true;

	} else {
		// setAllowEvaluatorFinalReportModification(false);
		toReturnStatus.evaluatorFinalReportModification = false;
	}

	return toReturnStatus;
}



