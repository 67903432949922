import { combineReducers } from 'redux';

import loginReducer from './login/loginReducer';
import researchActivitiesReducer from './researchActivities/researchActivitiesReducer';

const rootReducer = combineReducers({
	login: loginReducer,
	researchActivities: researchActivitiesReducer
})

export default rootReducer;