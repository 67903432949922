import React, { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Card from 'react-bootstrap/Card';

import { getUserAboutDetail } from '../../../../redux/userManagement/userManagementActions';

import FrontLoadingOverlay from '../../layout/FrontLoadingOverLay';

function UserProfileAboutPage(props) {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [userSlug, setUserSlug] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [userAboutDetail, setUserAboutDetail] = useState({});

	//Setting userSlug on param changes, 
	useEffect(() => {
		performUrlParamChanges();
	}, [params]);

	const performUrlParamChanges = () => {
		const userSlug = (params && 'user_slug' in params) ? params.user_slug : '';
		setUserSlug(userSlug);
	};

	useEffect(() => {
		if (userSlug && userSlug != '') {
			setIsLoading(true);
			let postData = {
				'slug': userSlug
			};
			dispatch(getUserAboutDetail(postData)).then((userAboutDetail) => {
				if (!userAboutDetail || userAboutDetail.length === 0) {
					navigate("/url_not_found");
				}
				setUserAboutDetail(userAboutDetail);
				setIsLoading(false);
			});
		}
	}, [userSlug]);
	return (
		<>

			<Card className="mt-3" style={{ background: ' #4c566a', color: '#fff' }} >
				<Card.Body style={{ padding: '0.4em' }}>
					<Card.Title style={{ marginBottom: 'unset' }}>About</Card.Title>
				</Card.Body>
			</Card>
			<Card className="mt-3">
				<FrontLoadingOverlay isLoading={isLoading} />
				<Card.Body>
					<h5>Bio</h5>
					<p>{userAboutDetail.bio}</p>
				</Card.Body>
			</Card>
		</>
	);
}
export default UserProfileAboutPage;