import * as researchActivitiesActionType from './researchActivitiesActionType';

const initialState = {
	loading: false,
	singleResearchActivityState: [],
	exploreSingleResearchDetail: {},
	progressReports: [],
	modificationFlags: {},
	error: ''
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		//For single research activity fetch starts

		case researchActivitiesActionType.SINGLE_RESEARCH_ACTIVITY_FETCH_REQUEST:
			return {
				...state,
				loading: true,
				error: '',
			}
		case researchActivitiesActionType.SINGLE_RESEARCH_ACTIVITY_FETCH_SUCCESS:
			return {
				...state,
				loading: false,
				singleResearchActivityState: action.payload,
				error: ''
			}
		case researchActivitiesActionType.SINGLE_RESEARCH_ACTIVITY_FETCH_FAILURE:
			return {
				...state,
				loading: false,
				singleResearchActivityState: [],
				error: action.payload
			}
		//For single research activity fetch ends
		//For exploring single research fetch starts
		case researchActivitiesActionType.EXPLORE_SINGLE_RESEARCH_FETCH_REQUEST:
			return {
				...state,
				exploreSingleResearchDetail: {},
				loading: true,
				error: '',
			}
		case researchActivitiesActionType.EXPLORE_SINGLE_RESEARCH_FETCH_SUCCESS:
			return {
				...state,
				loading: false,
				exploreSingleResearchDetail: action.payload,
				error: ''
			}
		case researchActivitiesActionType.EXPLORE_SINGLE_RESEARCH_FETCH_FAILURE:
			return {
				...state,
				loading: false,
				exploreSingleResearchDetail: {},
				error: action.payload
			}
		//For exploring single research fetch ends
		//For progress report fetch starts
		case researchActivitiesActionType.PROGRESS_REPORTS_FETCH_REQUEST:
			return {
				...state,
				progressReports: [],
				loading: true,
				error: '',
			}
		case researchActivitiesActionType.PROGRESS_REPORTS_FETCH_SUCCESS:
			return {
				...state,
				loading: false,
				progressReports: action.payload,
				error: ''
			}
		case researchActivitiesActionType.PROGRESS_REPORTS_FETCH_FAILURE:
			return {
				...state,
				loading: false,
				progressReports: [],
				error: action.payload
			}
		//For progress report fetch ends

		//For modification flags start here
		case researchActivitiesActionType.SET_RESEARCH_MODIFICATION_FLAGS:
			return {
				...state,
				modificationFlags: action.payload,
			}
		//For modification flags ends here

		default: return state
	}
}

export default reducer