import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Navigate, useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faBars, faHome, faBook, faUsers, faBinoculars } from "@fortawesome/free-solid-svg-icons";

import { getSessionData } from '../../../helpers/CommonHelper';

import Auth from '../../../helpers/Auth';
function MenuBar(props) {
	const [currentPathArray, setCurrentPathArray] = useState([]);
	let location = useLocation();

	useEffect(() => {
		performLocationChanges();
	}, [location]);

	const performLocationChanges = () => {
		const pathName = location.pathname;
		const pathArray = pathName.split('/');
		setCurrentPathArray(pathArray);
	}

	//Check Authentication
	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}
	return (
		<Container className="px-0" fluid style={{ position: 'sticky', top: '55px', zIndex: '1', background: '#1f324a' }}>
			<Navbar expand="lg">
				<Container fluid>
					<Navbar.Toggle aria-controls="navbarScroll" style={{ color: '#fff' }} ><FontAwesomeIcon icon={faBars} /> </Navbar.Toggle>
					<Navbar.Collapse id="navbarScroll" >
						<Nav
							className="me-auto my-2 my-lg-0"
							style={{ maxHeight: '100px' }}
							navbarScroll
						>
							<Nav>
								<Link replace={true} to="landing">
									<Button variant="outline-primary" className={'me-2 menu-btn ' + (currentPathArray.includes('landing') ? 'menu-active' : '')} style={{ fontSize: 'large', border: 'unset' }}>
										<FontAwesomeIcon icon={faHome} /> <br />Dashboard
									</Button>
								</Link>
							</Nav>
							<Nav >
								<Link replace={true} to="my_research_activities">
									<Button variant="outline-primary" className={'me-2 menu-btn ' + (currentPathArray.includes('my_research_activities') ? 'menu-active' : '')} style={{ fontSize: 'large', border: 'unset' }}>
										<FontAwesomeIcon icon={faBook} /> <br />Research Activities
									</Button>
								</Link>
							</Nav>
							{(getSessionData('level') === "0" || getSessionData('level') === "1") ?
								<Nav >
									<Link replace={true} to="evaluate_research/evaluate_research_proposals">
										<Button variant="outline-primary" className={'me-2 menu-btn ' + (currentPathArray.includes('evaluate_research') ? 'menu-active' : '')} style={{ fontSize: 'large', border: 'unset' }}>
											<FontAwesomeIcon icon={faBinoculars} /> <br />Explore Other's Research
										</Button>
									</Link>
								</Nav>
								:
								null
							}
							{(getSessionData('level') === "0") ?
								<>
									<Nav>
										<Link replace={true} to="user_management">
											<Button variant="outline-primary" className={'me-2 menu-btn ' + (currentPathArray.includes('user_management') ? 'menu-active' : '')} style={{ fontSize: 'large', border: 'unset' }}>
												<FontAwesomeIcon icon={faUsers} /> <br />User Management
											</Button>
										</Link>
									</Nav>
									<Nav>
										<Link replace={true} to="hierarchy_management">
											<Button variant="outline-primary" className={'me-2 menu-btn ' + (currentPathArray.includes('hierarchy_management') ? 'menu-active' : '')} style={{ fontSize: 'large', border: 'unset' }}>
												<FontAwesomeIcon icon={faLayerGroup} /> <br />Hierarchy Management
											</Button>
										</Link>
									</Nav>
								</>
								:
								null
							}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</Container>
	);
}
export default MenuBar;