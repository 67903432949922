import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import parse from 'html-react-parser';
import Auth from '../../../helpers/Auth';
import { getProposalStatusText } from '../../../helpers/CommonHelper';

function ProposalFeedbackView(props) {
	const [researchDetail, setResearchDetail] = useState({});

	useEffect(() => {
		setResearchDetail(props.research_detail);
	}, [props]);

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />;
	}

	return (
		<Card className="mt-2">
			<Card.Body className="custom-card-body">
				<div>
					<strong>Proposal status: </strong>
					<div>
						<Badge pill bg="primary">
							{getProposalStatusText(researchDetail.research_proposal_status)}
						</Badge>
					</div>
				</div>
				<div>
					<strong>Proposal feedback: </strong>
					<div>
						{researchDetail.research_proposal_feedback && researchDetail.research_proposal_feedback !== ''
							? parse(researchDetail.research_proposal_feedback)
							: '-'}
					</div>
				</div>
				<div>
					<strong>Feedback date: </strong>
					<div>
						{researchDetail.research_proposal_analysed_on && researchDetail.research_proposal_analysed_on !== ''
							? researchDetail.research_proposal_analysed_on
							: '-'}
					</div>
				</div>
			</Card.Body>
		</Card>
	);
}

export default ProposalFeedbackView;
