import React from 'react';
import { Navigate } from 'react-router-dom';

import { Card } from 'react-bootstrap';
import parse from 'html-react-parser';
import Auth from '../../../helpers/Auth';


const ProposalResearchCard = React.forwardRef((props, ref) => {
	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}
	return (
		<Card className="mt-3" ref={ref}>
			<Card.Body>
				<h5>{props.title}</h5>
				<hr />
				<div>{props.body ? parse(props.body) : ''}</div>
			</Card.Body>
		</Card>
	);
});

export default ProposalResearchCard;
