import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';
import Auth from '../../../helpers/Auth';

function ReportView(props) {
	const [allReports, setAllReports] = useState([]);

	useEffect(() => {
		const propsResearchReports = props.research_reports;
		if (propsResearchReports && propsResearchReports.length > 0) {
			const filteredReports = propsResearchReports.filter((record) => record.is_submitted === '1');
			setAllReports(filteredReports);
		}
	}, [props]);

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />;
	}

	return (
		<>
			<Card className="mt-3">
				<Card.Header className="custom-card-header">
					<h5>
						<strong>Research progress reports</strong>
					</h5>
				</Card.Header>
				{allReports && allReports.length > 0 ? (
					<Card.Body className="custom-card-body">
						{allReports.map((singleReport, key) => (
							<Card className="mt-3" key={key}>
								<Card.Body className="custom-card-body">{parse(singleReport.research_progress_detail) || ''}</Card.Body>
								<Card.Footer>
									<Row>
										<Col md={12}>
											<strong>Submitted on: {singleReport.submitted_on}</strong>
										</Col>
									</Row>
								</Card.Footer>
							</Card>
						))}
					</Card.Body>
				) : (
					<Card.Body className="custom-card-body">
						<strong>Progress reports not available</strong>
					</Card.Body>
				)}
			</Card>
		</>
	);
}

export default ReportView;
