import { getToken } from '../helpers/CommonHelper';
import { reinstateAccessToken } from '../redux/token/tokenAction';
import Auth from '../helpers/Auth';
import axios from "axios";

export const getAPIUrl = (callUrl) => {
	return process.env.REACT_APP_BACKENDAPI_URL + callUrl;
}

export const customAxios = (contentType = '') => {
	if (contentType === '') {
		contentType = 'application/json;charset=UTF-8';
	}

	// axios instance for making requests
	const instance = axios.create({
		// your other properties for axios instance
		// headers: axiosHeaders
	});

	// Add a request interceptor
	instance.interceptors.request.use(function (config) {
		// Do something before request is sent
		config.headers = {
			'Content-Type': contentType,
			'Authorization': "Bearer " + getToken('accessToken'),
			'Access-Control-Allow-Origin': '*',
		};
		return config;
	}, function (error) {
		// Do something with request error
		return Promise.reject(error);
	});

	instance.interceptors.response.use(function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	}, async (error) => {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		let actionToPerform = await handleAxiosErrors(error);
		switch (actionToPerform) {
			case 'RECALL_SAME_URL':
				let originalRequestHeaders = error.config.headers;
				let authorizationText = "Bearer " + getToken('accessToken');
				//Set authorixation text and set error config back again to call the last failed url
				originalRequestHeaders.Authorization = authorizationText;
				error.config.headers = originalRequestHeaders;
				return customAxios().request(error.config);
			// return instance.request(error.config);
			case 'RETURN_SAME_DATA':
				return Promise.reject(error);
			default:
				return Promise.reject(error);
		}
	});

	return instance;
};

export const getHeadersWithoutToken = () => {
	return {
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
		}
	};
}


export const getHeadersWithToken = () => {
	const token = getToken('accessToken');
	return {
		headers: {
			'Authorization': 'Bearer ' + token,
		}
	};
}

export const rectifyAxiosErrors = async (error) => {
	if (error.response) {
		// Request made and server responded
		const errorResponseCode = error.response.status;
		const errorResponseData = error.response.data
		let errorMessages = [];
		if (errorResponseCode === 400 || errorResponseCode === 401 || errorResponseCode === 403 || errorResponseCode === 404) {
			const errorResponseMessages = errorResponseData.messages;

			if (typeof errorResponseMessages === 'object' && errorResponseMessages !== null) {
				Object.keys(errorResponseMessages).forEach(key => {
					errorMessages.push(key + " : " + errorResponseMessages[key]);
				});
			}


			const singleMessage = errorMessages.join(', ');
			return singleMessage
		}
	} else if (error.request) {
		// The request was made but no response was received
		return "Response not obtained from server.";
	} else {
		// Something happened in setting up the request that triggered an Error
		return "AXIOS setting error. Contact developer.";
	}
}

export const handleAxiosErrors = async (error) => {
	let errorMessage = await rectifyAxiosErrors(error);
	let actionToPerform = 'RETURN_SAME_DATA';

	switch (errorMessage) {
		case 'error : Unavailable / Invalid Token':
			const isTokenReInstated = await reinstateAccessToken();
			if (isTokenReInstated['status']) {
				//Instead of Reloading whole page, we need to re-send this request without user knowing about access token expiry and re-instating
				// window.location.reload(); // Reload the current page
				actionToPerform = 'RECALL_SAME_URL';
				// logData('I need to reload')
			} else if (!isTokenReInstated['status']) {
				return await handleAxiosErrors(isTokenReInstated['data']);
			} else {
				Auth.logout(() => {
					window.location.reload();
				});
			}
			break;
		case 'error : Invalid refresh token':
		case 'error : Refresh token is empty':
		case 'error : Refresh token does not exist in DB. Need to authenticate again.':
		case 'error : Error while creating access token':
			Auth.logout(() => window.location.reload());
			break;
		default:
			break;
	}
	return actionToPerform;
}
