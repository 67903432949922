import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import Auth from '../../../helpers/Auth';
import LoadingOverlay from '../layout/LoadingOverlay';
import Form from 'react-bootstrap/Form';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from 'react-bootstrap/Badge';
import { faPenToSquare, faEye, faUserGraduate, faTrash, faFilterCircleXmark, faCircleExclamation, faCircleCheck, faPeopleGroup, faAward } from "@fortawesome/free-solid-svg-icons";

import DataTable from 'react-data-table-component';
import dataTableStyleConfig from '../../../config/DataTablesConfig.json';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { getAllResearchActivities, saveResearchActivity, changePublicAvailablityFlag } from '../../../redux/researchActivities/researchActivitiesActions';


const ResearchActivitiesListing = () => {
	const dispatch = useDispatch();
	const dataFetchedRef = useRef(false); //using useRef to call only once from useeffect, workaround is to remove strictmode from index.js
	const [isLoading, setIsLoading] = useState(false);
	const [researchActivitiesList, setResearchActivitiesList] = useState([]);
	const [filterText, setFilterText] = React.useState('');

	//Dynamic table rows according to obtained data
	const getPubliclyVisibleText = (visibilityStatus, visibilityText) => {
		return (visibilityStatus === '1') ? (<Badge bg="success">{visibilityText}</Badge>) : (<Badge bg="danger">{visibilityText}</Badge>)
	};

	const getProposalStatusText = (proposalStatus, proposalStatusText) => {
		let badgeColor;
		switch (proposalStatus) {
			case '0':
				badgeColor = 'info';
				break;
			case '1':
				badgeColor = 'success';
				break;
			case '2':
				badgeColor = 'danger';
				break;
			case '3':
				badgeColor = 'success';
				break;
			default:
				badgeColor = 'secondary';
				break;
		}
		return <Badge bg={badgeColor}>{proposalStatusText}</Badge>;
	};

	const getFinalReportStatusText = (proposalStatus, proposalStatusText) => {
		let badgeColor;
		switch (proposalStatus) {
			case '0':
				badgeColor = 'info';
				break;
			case '1':
				badgeColor = 'success';
				break;
			case '2':
				badgeColor = 'info';
				break;
			case '3':
				badgeColor = 'success';
				break;
			default:
				badgeColor = 'secondary';
				break;
		}
		return <Badge bg={badgeColor}>{proposalStatusText}</Badge>;
	};




	const changePublicAvailablity = async (researchId, val) => {
		const newVal = (val === "1") ? "0" : "1";

		const readyToChange = await showChangePublicAvailablilityConfirmDialog(newVal);
		if (!readyToChange) {
			return;
		}

		let postData = {
			researchId: researchId,
			public_availability: newVal,
		}


		dispatch(changePublicAvailablityFlag(postData)).then(() => {
			dispatch(getAllResearchActivities()).then((allResearchActivities) => {
				changeDataValues(allResearchActivities);
				return;
			});
		})
	}

	const showChangePublicAvailablilityConfirmDialog = (changeToStatus) => {
		return new Promise((resolve) => {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='confirm-dialog-container'>
							<h4 className='confirm-dialog-heading'>Are you sure you want to change to {(changeToStatus === "1") ? 'publicly available' : 'hide from public'}?</h4>
							<p className="text-danger"><strong>Making this reasearch publicly available means that this research can be searched by general public from ANP research portal.</strong></p>
							<div className="full-width">
								<Button variant='info' onClick={() => {
									resolve(false); // User clicked No
									onClose();
								}}> No, I dont want to change now.</Button>
								<Button variant="success" className="mx-2"
									onClick={() => {
										resolve(true); // User clicked Yes
										onClose();
									}}
								>Yes, I undertand and I want to change.</Button>
							</div>
						</div>
					);
				},
				onClickOutside: () => {
					resolve(false);//user clicked ourside
				},
			});
		});
	}


	const renderRowActionButtons = (researchId, researchTitle, proposalStatus, finalReportStatus, isPubliclyAvailable) => {
		let actionButtons = null;

		if (proposalStatus === '3') {
			// Proposal is saved
			actionButtons = (
				<>
					<Link replace={true} to={`update/${researchId}`} title="Edit proposal">
						<Button variant='info'><FontAwesomeIcon icon={faPenToSquare} /> Edit proposal</Button>
					</Link>
					<Button className="mx-1 mt-1" variant='danger' onClick={handleRowDelete}><FontAwesomeIcon icon={faTrash} /> Delete proposal</Button>
				</>
			);
		} else if (proposalStatus === '0') {
			// Proposal is submitted
			actionButtons = (
				<>
					<Link replace={true} to={`view_single_research/${researchId}`} title="View Research">
						<Button variant='info'> <FontAwesomeIcon icon={faEye} /> View submitted proposal</Button>
					</Link>
					<Button className="mx-1 mt-1" variant='danger' onClick={handleRowDelete}><FontAwesomeIcon icon={faTrash} /> Delete proposal</Button>
				</>
			);
		} else if (proposalStatus === '1') {
			// Proposal is accepted
			if (finalReportStatus === '3') {
				// Final report is evaluated
				actionButtons = (
					<>
						<Link replace={true} to={`view_single_research/${researchId}`} title="View Research">
							<Button variant='info'> <FontAwesomeIcon icon={faAward} /> View research activity</Button>
						</Link>
						<div className="p-1 mt-1">
							<Form.Check
								type="switch"
								id="custom-switch"
								label="Public Availability"
								checked={isPubliclyAvailable === '1'}
								onChange={e => changePublicAvailablity(researchId, isPubliclyAvailable)}
							/>
						</div>
					</>
				);
			} else {
				actionButtons = (
					<Link replace={true} to={`view_single_research/${researchId}`} title="View Research">
						<Button variant='info'> <FontAwesomeIcon icon={faUserGraduate} /> Continue research work</Button>
					</Link>
				);
			}
		} else if (proposalStatus === '2') {
			// Proposal is rejected
			actionButtons = (
				<Link replace={true} to={`view_single_research/${researchId}`} title="View Feedback">
					<Button variant='info'> <FontAwesomeIcon icon={faEye} /> View Feedback</Button>
				</Link>
			);
		}

		return (
			<div className="m-1">
				{actionButtons}
			</div>
		);
	};

	//Filtering and pagination - STARTS
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const [tableColumns, setTableColumns] = useState([
		{
			name: 'Project title',
			selector: row => row.research_title,
			sortable: true,
			allowOverflow: true,
		},
		{
			name: 'Proposal status',
			selector: row => row.research_proposal_status_text,
			sortable: true,
			cell: (row) => {
				return getProposalStatusText(row.research_proposal_status, row.research_proposal_status_text);
			},
			allowOverflow: true,
		},
		{
			name: 'Final report status',
			selector: row => row.final_report_status_text,
			sortable: true,
			cell: (row) => {
				return getFinalReportStatusText(row.final_report_status, row.final_report_status_text);
			},
			allowOverflow: true,
		},
		{
			name: 'Publicly visible',
			selector: row => row.public_availability_text,
			sortable: true,
			cell: (row) => {
				return getPubliclyVisibleText(row.public_availability, row.public_availability_text);
			},
			allowOverflow: true,
		},
		{
			name: 'Action',
			sortable: false,
			cell: (row) => {
				return renderRowActionButtons(row.id, row.research_title, row.research_proposal_status, row.final_report_status, row.public_availability)
			},
			ignoreRowClick: true,
			allowOverflow: true,
			left: true,

		},
	]);
	const filteredItems = researchActivitiesList.filter(
		item =>
			(item.research_title && item.research_title.toLowerCase().includes(filterText.toLowerCase()))
	);
	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<>
				<Form.Group controlId="table_search">
					<Form.Control type="text" placeholder="Search..."
						value={filterText || ""}
						onChange={e => setFilterText(e.target.value)} />
				</Form.Group>
				<Button size title="Reset filters" onClick={handleClear} variant='outline-secondary'><FontAwesomeIcon icon={faFilterCircleXmark} /></Button >
			</>
		);
	}, [filterText, resetPaginationToggle]);
	const handleRowDelete = (researchId, researchTitle) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='confirm-dialog-container'>
						<h4 className='confirm-dialog-heading'>Are you sure to delete?</h4>
						<p className="confirm-dialog-p">{researchTitle}</p>
						<div className="full-width">
							<Button variant='info' onClick={onClose}><FontAwesomeIcon icon={faCircleExclamation} /> No, I do not want to delete</Button>
							<Button variant="success" className="mx-2"
								onClick={() => {
									confirmDelete(researchId);
									onClose();
								}}
							>
								<FontAwesomeIcon icon={faCircleCheck} /> Yes, delete it!
							</Button>
						</div>
					</div>
				);
			}
		});
	};
	const confirmDelete = (researchId) => {
		if (!researchId || researchId === 0) return;

		//Perform Delete by changing the is_deleted flag
		let toModifyData = {};
		toModifyData.is_deleted = '1';
		toModifyData.id = researchId;
		dispatch(saveResearchActivity(toModifyData)).then(() => {
			setIsLoading(true);
			dispatch(getAllResearchActivities()).then((allList) => {
				changeDataValues(allList);
				setIsLoading(false);
			});
		});
	}
	//Filtering and pagination - ENDS

	// Runs ONCE after initial rendering
	useEffect(() => {
		if (dataFetchedRef.current) return;
		setIsLoading(true);
		dataFetchedRef.current = true;
		dispatch(getAllResearchActivities()).then((allResearchActivities) => {
			changeDataValues(allResearchActivities);
			setIsLoading(false);
		});
	}, []);

	const changeDataValues = async (data) => {
		const dataToModify = data.map((singleRow) => {
			const publicAvailabilityText = (singleRow.public_availability === '1') ? 'Yes' : 'No';

			//For research proposal starts
			let researchProposalStatusText = 'ERROR';
			switch (singleRow.research_proposal_status) {
				case '0':
					researchProposalStatusText = 'Submitted';
					break;
				case '1':
					researchProposalStatusText = 'Accepted';
					break;
				case '2':
					researchProposalStatusText = 'Rejected';
					break;
				case '3':
					researchProposalStatusText = 'Saved';
					break;
				default:
					researchProposalStatusText = 'NA';
					break;
			}
			//For research proposal ends

			//For final report starts
			let finalReportStatusText = 'ERROR';
			switch (singleRow.final_report_status) {
				case '0':
					finalReportStatusText = 'Not Submitted';
					break;
				case '1':
					finalReportStatusText = 'Submitted';
					break;
				case '2':
					finalReportStatusText = 'Evaluation on process';
					break;
				case '3':
					finalReportStatusText = 'Evaluated';
					break;
				default:
					finalReportStatusText = 'NA';
					break;
			}
			//For final report ends
			return {
				...singleRow,
				public_availability_text: publicAvailabilityText,
				research_proposal_status_text: researchProposalStatusText,
				final_report_status_text: finalReportStatusText,
			};
		});
		setResearchActivitiesList(dataToModify);
	};

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}

	return (
		<div>
			<Card className="shadow-5">
				<Card.Body>
					<LoadingOverlay isLoading={isLoading} />
					{
						(() => {
							if (!researchActivitiesList || researchActivitiesList.length <= 0) {
								return (
									<div>
										<h5>No records currently available</h5>
									</div>
								)
							} else {
								return (
									<>
										<DataTable
											title="List of my research activities"
											pagination
											paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
											columns={tableColumns}
											data={filteredItems}
											fixedHeader
											subHeader
											subHeaderComponent={subHeaderComponentMemo}
											customStyles={dataTableStyleConfig.backendDataTableStyleConfig}
										/>
									</>
								)
							}
						})()
					}

				</Card.Body>
			</Card>
		</div>
	)
}

export default ResearchActivitiesListing;