
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import Auth from '../../../helpers/Auth';
import LoadingOverlay from '../layout/LoadingOverlay';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faFilterCircleXmark, faCircleExclamation, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import DataTable from 'react-data-table-component';
import dataTableStyleConfig from '../../../config/DataTablesConfig.json';

import { getAllHierarchies } from '../../../redux/hierarchyManagement/hierarchyManagementActions';

const HierarchyListing = () => {
	const dispatch = useDispatch();
	const dataFetchedRef = useRef(false); //using useRef to call only once from useeffect, workaround is to remove strictmode from index.js
	const [isLoading, setIsLoading] = useState(false);
	let [hierarchiesList, setHierarchiesList] = useState([]);
	const [filterText, setFilterText] = React.useState('');
	//Filtering and pagination - STARTS
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const [tableColumns, setTableColumns] = useState([
		{
			name: 'Hierarchy name',
			selector: row => row.hierarchy_name,
			sortable: true,
		},
		{
			name: 'Level',
			selector: row => 'Level ' + row.level,
			sortable: true,
		},
		{
			name: 'Action',
			sortable: false,
			cell: (row) =>
				<>
					<Link replace={true} to={`/dashboard/hierarchy_management/update/${row.id}`} title="Edit Record">
						<Button size="sm" variant='info'><FontAwesomeIcon icon={faPenToSquare} /></Button>
					</Link>
				</>,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	]);
	const filteredItems = hierarchiesList.filter(
		item =>
			(item.hierarchy_name && item.hierarchy_name.toLowerCase().includes(filterText.toLowerCase())) ||
			(item.level && item.level.toLowerCase().includes(filterText.toLowerCase()))
	);
	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<>
				<Form.Group controlId="table_search">
					<Form.Control type="text" placeholder="Search..."
						value={filterText || ""}
						onChange={e => setFilterText(e.target.value)} />
				</Form.Group>
				<Button size title="Reset filters" onClick={handleClear} variant='outline-secondary'><FontAwesomeIcon icon={faFilterCircleXmark} /></Button >
			</>
		);
	}, [filterText, resetPaginationToggle]);
	//Filtering and pagination - ENDS

	// Runs ONCE after initial rendering
	useEffect(() => {
		if (dataFetchedRef.current) return;
		setIsLoading(true);
		dataFetchedRef.current = true;
		dispatch(getAllHierarchies()).then((allHierarchies) => {
			setHierarchiesList(allHierarchies);
			setIsLoading(false);
		});
	}, []);


	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}

	return (
		<div>
			<Card className="shadow-5">
				<Card.Body>
					<LoadingOverlay isLoading={isLoading} />
					{
						(() => {
							if (!hierarchiesList || hierarchiesList.length <= 0) {
								return (
									<div>
										<h5>No records currently available</h5>
									</div>
								)
							} else {
								return (
									<>
										<DataTable
											title="List of all hierarchies"
											pagination
											paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
											columns={tableColumns}
											data={filteredItems}
											fixedHeader
											subHeader
											subHeaderComponent={subHeaderComponentMemo}
											customStyles={dataTableStyleConfig.backendDataTableStyleConfig}
										/>
									</>
								)
							}
						})()
					}

				</Card.Body>
			</Card>
		</div>
	)
}

export default HierarchyListing;