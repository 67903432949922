import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import { Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import parse from 'html-react-parser';

import ResearchCard from './ResearchCard';

function ResearchDetailView(props) {
	const researchDetail = props.research_detail;
	const calledFrom = props.called_from;

	const descriptionRef = useRef(null);
	const activityOutcomesRef = useRef(null);
	const activityOutputsRef = useRef(null);
	const activityImpactRef = useRef(null);

	const scrollToRef = (ref) => {
		if (ref.current) {
			window.scrollTo(0, ref.current.offsetTop - 265);
		}
	};
	return (
		<>
			<Container fluid>
				<Card className={`mt-3 mb-3 px-2 ${(calledFrom === 'frontend') ? 'research-detail-card-front' : 'research-detail-card-back'}`}>

					<Card.Body style={{ padding: '0.4em' }}>
						<Row className={`${(calledFrom === 'frontend') ? 'research-detail-sticky-front' : 'research-detail-sticky-back'}`}>
							<Col md={12}>
								<Card className="mt-3" >
									<Card.Body style={{ padding: '0.4em' }}>
										<Card.Title style={{ marginBottom: 'unset', padding: '0.4em' }}>{researchDetail.research_title}</Card.Title>
									</Card.Body>
								</Card>
							</Col>
							<Col md={12}>
								<Card className="mt-3" >
									<Card.Body style={{ padding: '0.4em', fontWeight: 'bold' }}>
										<Nav defaultActiveKey="/home" as="ul">
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(descriptionRef)}>Description</Nav.Link></Nav.Item>
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(activityOutcomesRef)}>Activity Outcomes</Nav.Link></Nav.Item>
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(activityOutputsRef)}>Activity Outputs</Nav.Link></Nav.Item>
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(activityImpactRef)}>Activity Impacts</Nav.Link></Nav.Item>
										</Nav>
									</Card.Body>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col md={2}>
								<Card className="mt-3">
									<Card.Body>
										<Container>
											<Row>
												<Col md={12} className='mb-2'>
													<center><Image src={process.env.PUBLIC_URL + '/images/dummy_research_file_avatar.png'} width="100" height="100" thumbnail /></center>
												</Col>
												<hr />
												<Col md={12} className="mt-1">
													<h6>Author(s)</h6>
													<ul>
														{(calledFrom === 'frontend') ?
															<li>
																<Link replace={true} to={`/experts/${researchDetail.research_author_slug}`} title="View expert">
																	<p style={{ marginBottom: '0px' }}><strong>{researchDetail.research_author}</strong></p>
																</Link>
															</li>
															:
															<li>
																<p style={{ marginBottom: '0px' }}><strong>{researchDetail.research_author}</strong></p>
															</li>
														}
													</ul>
												</Col>
												<hr />
												<Col md={12} className="mt-1">
													<h6>Research date</h6>
													<ul>
														<li>
															<p style={{ marginBottom: '0px' }}><strong>{researchDetail.research_date}</strong></p>
														</li>
													</ul>
												</Col>
												<hr />
												<Col md={12} className="mt-1">
													<h6>Authorship attribution</h6>
													<div>{(researchDetail.research_authorship_attribution && researchDetail.research_authorship_attribution !== '') ? parse(researchDetail.research_authorship_attribution) : ''}</div>
												</Col>
												<hr />
											</Row>
										</Container>
									</Card.Body>
								</Card>
							</Col>
							<Col md={10}>
								<ResearchCard title="Description" body={researchDetail.description} ref={descriptionRef} />
								<ResearchCard title="Activity Outcomes" body={researchDetail.research_activity_outcomes} ref={activityOutcomesRef} />
								<ResearchCard title="Activity Outputs" body={researchDetail.research_activity_outputs} ref={activityOutputsRef} />
								<ResearchCard title="Activity Impacts" body={researchDetail.research_activity_impact} ref={activityImpactRef} />
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Container>
		</>
	);
}
export default ResearchDetailView;