import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ResearchDetailView from '../../common/ResearchDetailView';

import { getSingleResearchDetailForPublic } from '../../../redux/researchActivities/researchActivitiesActions';
import FrontLoadingOverlay from '../layout/FrontLoadingOverLay';

function ResearchDetail(props) {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [researchSlug, setResearchSlug] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [researchDetail, setResearchDetail] = useState({});

	//Setting researchSlug on param changes, 
	useEffect(() => {
		performUrlParamChanges();
	}, [params]);

	const performUrlParamChanges = () => {
		const researchSlug = (params && 'research_activity_slug' in params) ? params.research_activity_slug : '';
		setResearchSlug(researchSlug);
	};

	useEffect(() => {
		if (researchSlug && researchSlug != '') {
			setIsLoading(true);
			let postData = {
				'slug': researchSlug
			};
			dispatch(getSingleResearchDetailForPublic(postData)).then((researchDetail) => {
				if (!researchDetail || Object.keys(researchDetail).length === 0) {
					navigate("/url_not_found");
				}
				setResearchDetail(researchDetail);
				setIsLoading(false);
			});
		}
	}, [researchSlug]);
	return (
		<>
			<FrontLoadingOverlay isLoading={isLoading} />
			<ResearchDetailView research_detail={researchDetail} called_from="frontend" />
		</>
	)
}

export default ResearchDetail;