import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Navigate, useLocation } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faBars, faChartPie, faThumbsUp, faUsers, faBinoculars } from "@fortawesome/free-solid-svg-icons";

import Auth from '../../../helpers/Auth';

function EvaluateResearchMenu(props) {
	const [currentPathArray, setCurrentPathArray] = useState([]);
	let location = useLocation();

	useEffect(() => {
		performLocationChanges();
	}, [location]);

	const performLocationChanges = () => {
		const pathName = location.pathname;
		const pathArray = pathName.split('/');
		setCurrentPathArray(pathArray);
	}

	//Check Authentication
	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}
	return (
		<Container className="px-0 mb-3" fluid style={{ position: 'sticky', top: '138px', zIndex: '100', background: '#1f324a' }}>
			<Navbar expand="lg">
				<Container fluid>
					<Navbar.Toggle aria-controls="navbarScroll" style={{ color: '#fff' }} ><FontAwesomeIcon icon={faBars} /> </Navbar.Toggle>
					<Navbar.Collapse id="navbarScroll" >
						<Nav
							className="me-auto my-2 my-lg-0"
							style={{ maxHeight: '100px' }}
							navbarScroll
						>
							<Nav>
								<Link replace={true} to="/dashboard/evaluate_research/evaluate_research_proposals">
									<Button variant="outline-primary" className={'me-2 menu-btn ' + (currentPathArray.includes('evaluate_research_proposals') ? 'menu-active' : '')} style={{ fontSize: 'large', border: 'unset' }}>
										<FontAwesomeIcon icon={faPaperPlane} /> <br />Research proposals
									</Button>
								</Link>
							</Nav>
							<Nav>
								<Link replace={true} to="/dashboard/evaluate_research/evaluate_ongoing_researches">
									<Button variant="outline-primary" className={'me-2 menu-btn ' + (currentPathArray.includes('evaluate_ongoing_researches') ? 'menu-active' : '')} style={{ fontSize: 'large', border: 'unset' }}>
										<FontAwesomeIcon icon={faChartPie} /> <br />Ongoing researches
									</Button>
								</Link>
							</Nav>
							<Nav>
								<Link replace={true} to="/dashboard/evaluate_research/evaluate_completed_researches">
									<Button variant="outline-primary" className={'me-2 menu-btn ' + (currentPathArray.includes('evaluate_completed_researches') ? 'menu-active' : '')} style={{ fontSize: 'large', border: 'unset' }}>
										<FontAwesomeIcon icon={faThumbsUp} /> <br />Completed researches
									</Button>
								</Link>
							</Nav>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</Container>
	);
}
export default EvaluateResearchMenu;