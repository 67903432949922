import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Navigate, useParams } from "react-router-dom";

import EvaluateResearchMenu from './EvaluateResearchMenu';
import ExploreResearchActivitiesListing from './ExploreResearchActivitiesListing';
import SingleResearchDetail from '../single_research_detail/SingleResearchDetail';

import Auth from '../../../helpers/Auth';


import { getResearchActivitiesToExplore, fetchSingleResearchActivity } from '../../../redux/researchActivities/researchActivitiesActions';


function EvaluateResearch(props) {
	const dispatch = useDispatch();
	const params = useParams();
	// const dataFetchedRef = useRef(false); //using useRef to call only once from useeffect, workaround is to remove strictmode from index.js
	const [isLoading, setIsLoading] = useState(false);
	const [researchActivitiesList, setResearchActivitiesList] = useState([]);
	// const [singleResearchData, setSingleResearchData] = useState({});

	const [researchType, setResearchType] = useState('');
	const [researchId, setResearchId] = useState('');

	//Setting researchId on param changes starts, 
	useEffect(() => {
		performUrlParamChanges();
	}, [params]);

	const performUrlParamChanges = () => {
		const researchType = (params && 'research_type' in params) ? params.research_type : '';
		const researchId = (params && 'research_id' in params) ? params.research_id : '';
		setResearchType(researchType);
		setResearchId(researchId);
	};

	//Get list to pass to table compoenent
	useEffect(() => {
		if (researchType && researchType !== '') {
			setIsLoading(true);
			let dataToFetch = {
				researchType: researchType,
			}
			dispatch(getResearchActivitiesToExplore(dataToFetch)).then((allData) => {
				setResearchActivitiesList(allData);
				setIsLoading(false);
			});
		}
	}, [researchType]);

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}

	return (
		<>
			<EvaluateResearchMenu />
			{
				(() => {
					if (researchType && researchType !== '') {
						return (
							<ExploreResearchActivitiesListing data_list={researchActivitiesList} />
						)
					} else if (researchId && researchId !== '') {
						return (
							<SingleResearchDetail research_id={researchId} viewer_role="evaluator" />
						)
					}
				})()
			}
		</>
	)
}
export default EvaluateResearch;