import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { Alert } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TinyEditorField from '../../layout/TinyEditorField';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Auth from '../../../../helpers/Auth';
import { saveFinalReport, exploreSingleResearchDetail } from '../../../../redux/researchActivities/researchActivitiesActions';
import FinalReportView from '../FinalReportView';

const validationSchema = Yup.object().shape({
	final_report: Yup.string().required('Final report is required'),
});

function ResearcherFinalReport(props) {
	const dispatch = useDispatch();
	const [researchId, setResearchId] = useState('');
	// const [researchDetail, setResearchDetail] = useState({});
	// const [allowModification, setAllowModification] = useState(false);
	const [clickedButton, setClickedButton] = useState("");
	const [formFields, setFormFields] = useState({
		final_report: '',
		research_next_steps: '',
		research_activity_outputs: '',
		research_activity_outcomes: '',
		research_activity_impact: ''
	});
	const [isLoading, setIsLoading] = useState(false);
	const [reportUpdateId, setReportUpdateId] = useState('');
	const researchDetail = useSelector(state => state.researchActivities.exploreSingleResearchDetail); //Same as mapStateToProps
	const allowModification = useSelector(state => state.researchActivities.modificationFlags.researcherFinalReportModification); //Same as mapStateToProps

	useEffect(() => {
		setResearchId(props.research_id);
		// setResearchDetail(props.research_detail);
		// setAllowModification(props.allow_modification);
	}, [props]);

	useEffect(() => {
		if (researchDetail && 'id' in researchDetail) {
			if (researchDetail.final_report_status === '0') {
				setFormFields(researchDetail);
			}
		}
	}, [researchDetail]);

	const obtainSingleRecordFromDB = () => {
		const postData = {
			researchId: researchId
		};
		dispatch(exploreSingleResearchDetail(postData));
	}

	const handleSubmit = async (values, actions) => {
		try {
			const postData = {
				id: researchId,
				final_report: values.final_report,
				research_next_steps: values.research_next_steps,
				research_activity_outputs: values.research_activity_outputs,
				research_activity_outcomes: values.research_activity_outcomes,
				research_activity_impact: values.research_activity_impact,
			};

			if (clickedButton === "save_progress") {
				postData.final_report_status = "0";
			} else if (clickedButton === "save_submit") {
				const readyToSubmit = await showSubmitDialog();
				if (!readyToSubmit) {
					return;
				}
				postData.final_report_status = "1";
			}

			setIsLoading(true);
			const savedStatus = await dispatch(saveFinalReport(postData));
			if (savedStatus) {
				obtainSingleRecordFromDB();
			}

		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	const showSubmitDialog = () => {
		return new Promise((resolve) => {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='confirm-dialog-container'>
							<h4 className='confirm-dialog-heading'>Are you sure to submit?</h4>
							<p className="text-danger"><strong>You cannot make further changes once you confirm the submission.</strong></p>
							<div className="full-width">
								<Button variant='info' onClick={() => {
									resolve(false);
									onClose();
								}}>No, I want to keep working.</Button>
								<Button variant="success" className="mx-2"
									onClick={() => {
										resolve(true);
										onClose();
									}}
								>Yes, I understand and I want to submit.</Button>
							</div>
						</div>
					);
				},
				onClickOutside: () => {
					resolve(false);
				},
			});
		});
	}

	const isInvalid = (errors, touched, fieldName) => errors[fieldName] && touched[fieldName];

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />;
	}

	return (
		<>
			{allowModification && researchDetail.final_report_status === '0' ? (
				<>
					<Card className="mt-3">
						<Card.Header className="custom-card-header">
							<h4>Final report</h4>
						</Card.Header>
						<Card.Body className="custom-card-body">
							<Alert variant="primary">
								<strong>Note:</strong>
								<br />
								<span>
									Only a solitary final report can be furnished in this context, and it should be noted that once the final report has been submitted, no further modifications or amendments can be made to its content, but you can save your final report multiple times during the course of your research.
								</span>
							</Alert>
							<Row>
								<Formik
									initialValues={formFields}
									validationSchema={validationSchema}
									onSubmit={handleSubmit}
									enableReinitialize={true}
								>
									{({ errors, touched, values, handleChange }) => (
										<>
											<Form>
												<Row>
													<Col md={12}>
														<>
															<label htmlFor="final_report"><strong>Provide final report for your research.</strong></label>
															<Field name="final_report" component={TinyEditorField} />
														</>
													</Col>
												</Row>
												<hr />
												<Row className="mt-2">
													<Col md={6}>
														<div className="mt-1">
															<label htmlFor="research_next_steps"><strong>Next steps - (What will be the next steps on the project)</strong></label>
															<Field name="research_next_steps" component={TinyEditorField} />
														</div>
														<div className="mt-4">
															<label htmlFor="research_activity_outputs"><strong>Research outputs - (Outputs of the research)</strong></label>
															<Field name="research_activity_outputs" component={TinyEditorField} />
														</div>
													</Col>
													<Col md={6}>
														<div className="mt-1">
															<label htmlFor="research_activity_outcomes"><strong>Research outcomes - (Outcomes of the research)</strong></label>
															<Field name="research_activity_outcomes" component={TinyEditorField} />
														</div>
														<div className="mt-4">
															<label htmlFor="research_activity_impact"><strong>Research impact - (Impact of the research)</strong></label>
															<Field name="research_activity_impact" component={TinyEditorField} />
														</div>
													</Col>
												</Row>
												<Row>
													<Col md={12}>
														<input type="submit" className="btn btn-primary mt-3 mx-2" name="save_progress" value="Save and continue later" onClick={e => setClickedButton(e.target.name)} />
														<input type="submit" className="btn btn-success mt-3 mx-2" name="save_submit" value="Save and submit" onClick={e => setClickedButton(e.target.name)} />
													</Col>
												</Row>
											</Form>
											{Object.keys(errors).length > 0 && (
												<div className="mt-2 alert alert-danger">
													Please fix the validation errors before submission.
												</div>
											)}
										</>
									)}
								</Formik>
							</Row>
						</Card.Body>
					</Card>
				</>
			) : (
				<Card className="mt-3">
					<Card.Body className="custom-card-body">
						<Alert variant="primary">
							<strong>Note:</strong>
							<br />
							<span>
								Modification of the final report is strictly prohibited at this stage. Changes to the final report can only be made under the following circumstances:
							</span>
							<ul>
								<li>Upon acceptance of your proposal.</li>
								<li>Provided that you have submitted at least one progress report.</li>
								<li>Ensuring that the final report has not been submitted yet.</li>
							</ul>
							<span>Please be aware that these conditions must be met in order to allow any revisions or amendments to the final report.</span>
						</Alert>
					</Card.Body>
				</Card>
			)}
			<FinalReportView research_detail={researchDetail} />
		</>
	);
}

export default ResearcherFinalReport;
