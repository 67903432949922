import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import ReportView from '../ReportView';

import Auth from '../../../../helpers/Auth';

function EvaluatorProgressReport(props) {
	const [researchId, setResearchId] = useState('');

	const allReports = useSelector(state => state.researchActivities.progressReports); //Same as mapStateToProps


	//Run once and everytime after props changes
	useEffect(() => {
		setResearchId(props.research_id);
	}, [props]);

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}

	return (
		<>
			<ReportView research_reports={allReports} />
		</>
	);
}
export default EvaluatorProgressReport;