import React, { useState, useEffect } from 'react';
import { Navigate, Routes, Route, Link, useLocation, useParams } from "react-router-dom";

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faList } from "@fortawesome/free-solid-svg-icons";

import Auth from '../../../helpers/Auth';



import ModifyResearchActivity from './ModifyResearchActivity';
import ResearchActivitiesListing from './ResearchActivitiesListing';
import SingleResearchDetail from '../single_research_detail/SingleResearchDetail';

const ResearchActivities = () => {
	const params = useParams();
	const [currentPathArray, setCurrentPathArray] = useState([]);
	const [researchId, setResearchId] = useState('');

	let location = useLocation();


	//Setting researchId on param changes starts, 
	useEffect(() => {
		performUrlParamChanges();
	}, [params]);

	const performUrlParamChanges = () => {
		const researchId = (params && 'research_id' in params) ? params.research_id : '';
		setResearchId(researchId);
	};


	useEffect(() => {
		performLocationChanges();
	}, [location]);

	const performLocationChanges = () => {
		const pathName = location.pathname;
		const pathArray = pathName.split('/');
		setCurrentPathArray(pathArray);
	}

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}
	return (
		<>
			<Card className="shadow-5">
				<Card.Header>
					<div className="research-record-heading">
						{
							(() => {
								if (currentPathArray.includes('view_single_research')) {
									return (
										<>
											<h4> My Research Progress</h4>
										</>
									)
								}
								else if (!currentPathArray.includes('new') && !currentPathArray.includes('update')) {
									return (
										<>
											<h4> My Research Activities</h4>
											<Link replace={true} to="new" align="end">
												<Button className="mb-2" variant="outline-primary">
													<FontAwesomeIcon icon={faCirclePlus} /> Add new proposal
												</Button>
											</Link>
										</>
									)
								} else {
									return (
										<>
											<h4> Modify Research Activity</h4>
											<Link replace={true} to="/dashboard/my_research_activities">
												<Button className="mb-2" variant="outline-primary">
													<FontAwesomeIcon icon={faList} /> View list
												</Button>
											</Link>
										</>
									)
								}

							})()
						}
					</div>
				</Card.Header>
				<Card.Body>
					{
						(() => {
							if (researchId && researchId !== '') {
								return (
									<SingleResearchDetail research_id={researchId} viewer_role="researcher" />
								)
							} else {
								return (
									<Routes>
										<Route path="/" element={<ResearchActivitiesListing />} />
										<Route path="/new" element={<ModifyResearchActivity />} />
										<Route path="/update/*" element={<ModifyResearchActivity />} />
									</Routes>

								)
							}
						})()
					}
				</Card.Body>
			</Card>
		</>
	);

}

export default ResearchActivities;