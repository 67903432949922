import * as researchActivitiesActionType from './researchActivitiesActionType';

//Fetch single research activity

export const singleResearchActivityFetchRequest = () => {
	return {
		type: researchActivitiesActionType.SINGLE_RESEARCH_ACTIVITY_FETCH_REQUEST
	}
}

export const singleResearchActivityFetchSuccess = responseData => {
	return {
		type: researchActivitiesActionType.SINGLE_RESEARCH_ACTIVITY_FETCH_SUCCESS,
		payload: responseData
	}
}

export const singleResearchActivityFetchFailure = error => {
	return {
		type: researchActivitiesActionType.SINGLE_RESEARCH_ACTIVITY_FETCH_FAILURE,
		payload: error
	}
}


//Explore single research

export const exploreSingleResearchActivityFetchRequest = () => {
	return {
		type: researchActivitiesActionType.EXPLORE_SINGLE_RESEARCH_FETCH_REQUEST
	}
}

export const exploreSingleResearchActivityFetchSuccess = responseData => {
	return {
		type: researchActivitiesActionType.EXPLORE_SINGLE_RESEARCH_FETCH_SUCCESS,
		payload: responseData
	}
}

export const exploreSingleResearchActivityFetchFailure = error => {
	return {
		type: researchActivitiesActionType.EXPLORE_SINGLE_RESEARCH_FETCH_FAILURE,
		payload: error
	}
}

//Research Progress Reports


export const researchProgressReportsFetchRequest = () => {
	return {
		type: researchActivitiesActionType.PROGRESS_REPORTS_FETCH_REQUEST
	}
}

export const researchProgressReportsFetchSuccess = responseData => {
	return {
		type: researchActivitiesActionType.PROGRESS_REPORTS_FETCH_SUCCESS,
		payload: responseData
	}
}

export const researchProgressReportsFetchFailure = error => {
	return {
		type: researchActivitiesActionType.PROGRESS_REPORTS_FETCH_FAILURE,
		payload: error
	}
}


//for modification flags setting
export const setResearchModificationFlags = responseData => {
	return {
		type: researchActivitiesActionType.SET_RESEARCH_MODIFICATION_FLAGS,
		payload: responseData
	}
}