import React, { useEffect } from 'react';
const LoadingOverlay = (props) => {
	return (
		<>
			<div className={"loading-overlay " + (props.isLoading ? "is-active" : "")} id="loading_display">
				<div className="spinner-grow " role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		</>
	);
}

export default LoadingOverlay;