import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import UserProfileNavigation from './UserProfileComponents/UserProfileNavigation';
import UserProfilePersonalDetails from './UserProfileComponents/UserProfilePersonalDetails';
import UserProfileAboutPage from './UserProfileComponents/UserProfileAboutPage';
import UserProfileResearchOutput from './UserProfileComponents/UserProfileResearchOutput';

function UserProfile(props) {
	return (
		<>
			<Container className="mb-3" fluid>
				<Row>
					<Col md={12}>
						<UserProfileNavigation />
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<UserProfilePersonalDetails />
					</Col>
					<Col md={10}>
						<Routes>
							<Route path="/" element={<UserProfileAboutPage />} />
							<Route path="/about" element={<UserProfileAboutPage />} />
							<Route path="/research_outputs" element={<UserProfileResearchOutput />} />
						</Routes>
					</Col>
				</Row>
			</Container>


		</>
	)

}
export default UserProfile;