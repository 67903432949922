import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import { Alert } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TinyEditorField from '../../layout/TinyEditorField';

import Auth from '../../../../helpers/Auth';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { saveProgressReport, getProgressReports } from '../../../../redux/researchActivities/researchActivitiesActions';
import ReportView from '../ReportView';

const validationSchema = Yup.object().shape({
	research_progress_detail: Yup.string().required('Progress report is required'),
});

function ResearcherProgressReport(props) {
	const dispatch = useDispatch();
	const [researchId, setResearchId] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const [reportUpdateId, setReportUpdateId] = useState('');
	const [clickedButton, setClickedButton] = useState("");

	const allReports = useSelector(state => state.researchActivities.progressReports); //Same as mapStateToProps
	const allowModification = useSelector(state => state.researchActivities.modificationFlags.researcherProgressReportModification); //Same as mapStateToProps


	const [formFields, setFormFields] = useState({
		research_progress_detail: '',
	});

	//Run once and everytime after props changes
	useEffect(() => {
		setResearchId(props.research_id);
	}, [props]);


	//do something when allReports changes
	useEffect(() => {
		if (allReports && allReports.length !== 0) {
			performAllReportsChanges(allReports);
		}
	}, [allReports]);

	const obtainReportsFromDB = () => {
		const postData = {
			'researchId': researchId
		};

		dispatch(getProgressReports(postData));
	}

	const performAllReportsChanges = (allReports) => {
		const latestUnsubmittedRow = allReports
			.filter((record) => record.is_submitted === "0")
			.sort((a, b) => parseInt(b.id) - parseInt(a.id))
			.shift();

		if (latestUnsubmittedRow && "research_id" in latestUnsubmittedRow) {
			//If latest unssubmitted row exists in database, then map the data
			setReportUpdateId(latestUnsubmittedRow.id);
			// setCurrentProgressReport(latestUnsubmittedRow.research_progress_detail);
			setFormFields({
				research_progress_detail: latestUnsubmittedRow.research_progress_detail,
			});

		} else {
			setReportUpdateId('');
			setFormFields({
				research_progress_detail: '',
			});
		}
	}

	const handleSubmit = async (values, actions) => {
		try {
			let postData = {
				research_id: researchId,
				research_progress_detail: values.research_progress_detail
			};

			if (reportUpdateId && reportUpdateId !== '') {
				postData.id = reportUpdateId;
			}

			// Handle the form submission based on the clickedButton value
			if (clickedButton === "save_progress") {
				// Save progress logic
				postData.is_submitted = "0";
			} else if (clickedButton === "save_submit") {
				// Save and submit logic
				const readyToSubmit = await showSubmitDialog();
				if (!readyToSubmit) {
					return;
				}
				postData.is_submitted = "1";
			}

			setIsLoading(true);
			const savedStatus = await dispatch(saveProgressReport(postData));
			if (savedStatus) {
				obtainReportsFromDB();
			}
			//Reset the form with the set form fields
			actions.resetForm({
				values: { formFields },
			});
		} catch (error) {
			console.error(error);
		}
	};

	const showSubmitDialog = () => {
		return new Promise((resolve) => {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className='confirm-dialog-container'>
							<h4 className='confirm-dialog-heading'>Are you sure to submit?</h4>
							<p className="text-danger"><strong>You cannot make further changes once you confirm the submission.</strong></p>
							<div className="full-width">
								<Button variant='info' onClick={() => {

									resolve(false); // User clicked No
									onClose();
								}}> No, I want to keep working.</Button>
								<Button variant="success" className="mx-2"
									onClick={() => {
										resolve(true); // User clicked Yes
										onClose();
									}}
								>Yes, I undertand and I want to submit.</Button>
							</div>
						</div>
					);
				},
				onClickOutside: () => {
					resolve(false);//user clicked ourside
				},
			});
		});
	}

	const isInvalid = (errors, touched, fieldName) => errors[fieldName] && touched[fieldName];

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}

	return (
		<>
			{(allowModification) ?
				<>
					<Card className="mt-3">
						<Card.Header className="custom-card-header">
							<h4>Progress report</h4>

						</Card.Header>
						<Card.Body className="custom-card-body">
							<Alert variant="primary">
								<strong>Note:</strong>
								<br />
								<span>It is permissible to provide multiple progress reports throughout the duration of your research endeavor. </span>
								<br />
								<span>However, it is important to note that once a progress report has been submitted, it is not possible to make subsequent modifications to the previously submitted report.</span>
								<br />
								<span>Also, once you submit the final report, you cannot add any more progress reports for this research.</span>
							</Alert>
							<Row>
								<Formik
									initialValues={formFields}
									validationSchema={validationSchema}
									onSubmit={handleSubmit}
									enableReinitialize={true}
								>
									{({ errors, touched, values, handleChange }) => (
										<>
											<Form>
												<Col md={12}>
													<div className="mt-1">
														<label htmlFor="research_progress_detail"><strong>Provide your progress report</strong></label>
														< Field name="research_progress_detail" component={TinyEditorField} />
													</div>
												</Col>
												<Col md={12}>
													<input type="submit" className="btn btn-primary mt-3 mx-2" name="save_progress" value="Save and continue later" onClick={e => setClickedButton(e.target.name)} />
													<input type="submit" className="btn btn-success mt-3 mx-2" name="save_submit" value="Save and submit" onClick={e => setClickedButton(e.target.name)} />
												</Col>
											</Form>
											{Object.keys(errors).length > 0 && isInvalid(errors, touched, 'research_progress_detail') && (
												<div className=" mt-2 alert alert-danger">
													Please fix the validation errors before submission.
												</div>
											)}
										</>
									)}
								</Formik>
							</Row>
						</Card.Body>
					</Card>
				</>
				:
				<Card className="mt-3">
					<Card.Body className="custom-card-body">
						<Alert variant="primary">
							<strong>Note:</strong>
							<br />
							<span>Additional reports are prohibited from being included in the current research activity.</span>
							<br />
							<span>Please note that an additional report can only be appended under the following circumstances:</span>
							<ul>
								<li>Upon the acceptance of your proposal.</li>
								<li>Provided that you have not yet submitted your final report.</li>
							</ul>
							<span>It is advised to refer to the aforementioned criteria in order to determine the feasibility of incorporating an additional report.</span>
						</Alert>
					</Card.Body>
				</Card>
			}
			<ReportView research_reports={allReports} />
		</>
	);
}
export default ResearcherProgressReport;