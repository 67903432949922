import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useLocation } from "react-router-dom";

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faList } from "@fortawesome/free-solid-svg-icons";


import ModifyHierarchyRecord from './ModifyHierarchyRecord';
import HierarchyListing from './HierarchyListing';
const HierarchyManagement = () => {

	const [currentPathArray, setCurrentPathArray] = useState([]);
	let location = useLocation();

	useEffect(() => {
		performLocationChanges();
	}, [location]);

	const performLocationChanges = () => {
		const pathName = location.pathname;
		const pathArray = pathName.split('/');
		setCurrentPathArray(pathArray);
	}
	return (
		<>
			<Card className="shadow-5">
				<Card.Header>
					<div className="research-record-heading">
						{
							(() => {
								if (!currentPathArray.includes('new') && !currentPathArray.includes('update')) {
									return (
										<>
											<h4> Hierarchies Listing</h4>
											<Link replace={true} to="new">
												<Button className="mb-2" variant="outline-primary">
													<FontAwesomeIcon icon={faCirclePlus} /> Add new record
												</Button>
											</Link>
										</>
									)
								} else {
									return (
										<>
											<h4> Modify Hierarchy Records</h4>
											<Link replace={true} to="/dashboard/hierarchy_management">
												<Button className="mb-2" variant="outline-primary">
													<FontAwesomeIcon icon={faList} /> View list
												</Button>
											</Link>
										</>
									)
								}

							})()
						}
					</div>
				</Card.Header>
				<Card.Body>
					<Routes>
						<Route>
							<Route path="/" element={<HierarchyListing />} />
							<Route path="/new" element={<ModifyHierarchyRecord />} />
							<Route path="/update/*" element={<ModifyHierarchyRecord />} />
						</Route>
					</Routes>
				</Card.Body>
			</Card>
		</>
	);

}

export default HierarchyManagement;