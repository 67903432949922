import axios from 'axios';
import { getAPIUrl, getHeadersWithoutToken, rectifyAxiosErrors } from '../../services/Services';
import * as notify from '../studentNotification/notificationAction';

export const searchFromFrontend = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('search');
			const axiosConfig = getHeadersWithoutToken();
			const response = await axios.post(callUrl, postData, axiosConfig);
			const responseData = response.data;
			if (responseData.status) {
				const searchData = responseData.data;
				resolve(searchData);
			} else {
				const errorMessage = responseData.msg;
				dispatch(notify.error({ message: errorMessage }));
				resolve(false);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve(false);
		}
	});
};