import * as loginActionType from './loginActionType'

const initialState = {
	loading: false,
	loginState: [],
	error: ''
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case loginActionType.LOGIN_REQUEST:
			return {
				...state,
				loading: true,
				error: '',
			}
		case loginActionType.LOGIN_SUCCESS:
			return {
				loading: false,
				loginState: action.payload,
				error: ''
			}
		case loginActionType.LOGIN_FAILURE:
			return {
				loading: false,
				loginState: [],
				error: action.payload
			}
		default: return state
	}
}

export default reducer
