import axios from 'axios'
import * as loginActionType from './loginActionType';
import { saveToLocalStorage, removeFromLocalStorage } from '../../helpers/CommonHelper';
import { getAPIUrl, getHeadersWithoutToken, rectifyAxiosErrors } from '../../services/Services';
import * as notify from '../studentNotification/notificationAction';

import Auth from '../../helpers/Auth';

export const performLogin = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('login');
			const axiosConfig = getHeadersWithoutToken();
			dispatch(loginRequest());
			const response = await axios.post(callUrl, postData, axiosConfig);
			const responseData = response.data;
			console.log('ResponseData: ' + responseData);
			console.log('JSONResponse: ' + JSON.stringify(response));
			console.dir(response);
			console.log('Response.Data: ' + response.data);
			console.log('response.data.status: ' + response.status);
			if (response.status) {
				const {
					access_token: accessToken,
					refresh_token: refreshToken,
					first_name: firstName,
					middle_name: middleName,
					last_name: lastName,
					hierarchy_id: hierarchyId,
					hierarchy_name: hierarchyName,
					is_publicly_searchable,
					level,
					email,
					username,
				} = responseData;
				const tokenData = { accessToken, refreshToken };
				const userSessionData = { firstName, middleName, lastName, hierarchyId, hierarchyName, is_publicly_searchable, level, email, username }
				saveToLocalStorage('tokenData', JSON.stringify(tokenData));
				saveToLocalStorage('userSessionData', JSON.stringify(userSessionData));
				dispatch(loginSuccess(responseData));
				dispatch(notify.success({ message: 'Login success.' }));
				Auth.login();
				resolve(true);
			} else {
				removeFromLocalStorage('tokenData');
				removeFromLocalStorage('userSessionData');
				const errorMessage = 'Error while performing login';
				dispatch(notify.error({ message: errorMessage }));
				dispatch(loginFailure(errorMessage));
				Auth.logout();
				resolve(false);
			}
		} catch (error) {
			const allErrorMessages = await rectifyAxiosErrors(error);
			removeFromLocalStorage('tokenData');
			removeFromLocalStorage('userSessionData');
			dispatch(loginFailure(allErrorMessages));
			dispatch(notify.error({ message: allErrorMessages }));
			Auth.logout();
			resolve(false);
		}
	});
};

export const loginRequest = () => {
	return {
		type: loginActionType.LOGIN_REQUEST
	}
}

export const loginSuccess = responseData => {
	return {
		type: loginActionType.LOGIN_SUCCESS,
		payload: responseData
	}
}

export const loginFailure = error => {
	return {
		type: loginActionType.LOGIN_FAILURE,
		payload: error
	}
}
