import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function DashboardCard(props) {

	return (
		<>
			<Card className="shadow-5 dashboard-card mt-2">
				<Card.Body>
					<Row>
						<Col md={12}>
							<p className="dashboard-card-heading-text">{props.heading}</p>
							<p className="dashboard-card-description">{props.description}</p>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}
export default DashboardCard;