import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import { searchFromFrontend } from '../../../redux/search/searchActions';

import SearchResults from '../SearchResults';
import FrontLoadingOverlay from '../layout/FrontLoadingOverLay';

function ProfileSearch(props) {
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [searchResult, setSearchResult] = useState({});
	const handleSubmit = event => {
		event.preventDefault();
		try {
			setIsLoading(true);
			let searchPostData = {};
			searchPostData.searchText = searchText;
			dispatch(searchFromFrontend(searchPostData)).then((obtainedSearchResult) => {
				setSearchResult(obtainedSearchResult);
				setIsLoading(false);
			});
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	}
	const handleChange = (event) => {
		setSearchText(event.target.value);
	};
	return (
		<>
			<Container fluid>
				<FrontLoadingOverlay isLoading={isLoading} />
				<Row>
					<Col xs={12} md={{ span: 4, offset: 4 }} className="mt-4">
						{/* <center> */}
						<Card className="shadow-5 mt-5" style={{ background: 'linear-gradient(90deg, #2c3e50, #4c566a)', color: '#FFF', border: 'none' }}>
							<Card.Header>
								<center><h2>ANP's academic and research expertise</h2></center>
								<center><h6>Our world-class researchers work with industry, community and government partners to better understand our world and improve people’s lives</h6></center>
							</Card.Header>
							<Card.Body style={{ color: '#000' }}>
								<form onSubmit={handleSubmit} autoComplete='off'>
									<Row>
										<Col md={11}>
											<FloatingLabel
												controlId="floatingInput"
												label="Search..."
												className="mb-3"
											>
												<Form.Control
													type="text"
													placeholder="Search..."
													value={searchText}
													onChange={handleChange}
												/>
											</FloatingLabel>
										</Col>
										<Col md={1} className="">
											<Button className="search-btn" variant="outline-info" type="submit">
												<FontAwesomeIcon icon={faMagnifyingGlass} />
											</Button>
										</Col>

									</Row>
								</form>
							</Card.Body>
						</Card>
						{/* </center> */}
					</Col>
					<SearchResults searchResult={searchResult} />
				</Row>
			</Container>
		</>
	)
}
export default ProfileSearch