import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

import Auth from '../../../helpers/Auth';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';

import { fetchUserProfile } from '../../../redux/userManagement/userManagementActions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

import { updateProfile } from '../../../redux/userManagement/userManagementActions';
import LoadingOverlay from '../layout/LoadingOverlay';
import { updateSessionDataInLocalStorage } from '../../../helpers/CommonHelper';
import * as notify from '../../../redux/studentNotification/notificationAction';

function Profile(props) {
	const dispatch = useDispatch();
	const dataFetchedRef = useRef(false); //using useRef to call only once from useeffect, workaround is to remove strictmode from index.js
	let navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [profileData, setProfileData] = useState({});
	const [selectedFile, setSelectedFile] = useState(null);


	const [displayOnlyFields, setDisplayOnlyFields] = useState([]);

	const [bio, setBio] = useState('');
	const [isPubliclySearchable, setIsPubliclySearchable] = useState('0');

	//Run on initial render only once
	useEffect(() => {
		if (dataFetchedRef.current) return;
		setIsLoading(true);
		dataFetchedRef.current = true;
		dispatch(fetchUserProfile()).then((profileData) => {
			const displayOnlyFields = [
				{ id: 'firstName', label: 'First name', value: profileData.first_name },
				{ id: 'middleName', label: 'Middle name', value: profileData.middle_name },
				{ id: 'lastName', label: 'Last name', value: profileData.last_name },
				{ id: 'email', label: 'Email address', value: profileData.email },
				{ id: 'username', label: 'Username', value: profileData.username },
			];
			setProfileData(profileData);
			setDisplayOnlyFields(displayOnlyFields);
			//setting default form data starts
			setBio(profileData.bio);
			setIsPubliclySearchable(profileData.is_publicly_searchable);
			//setting default form data ends
			setIsLoading(false);
		});
	}, []);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file && file.type.startsWith('image/')) {
			setSelectedFile(file);
		} else {
			setSelectedFile(null);
			dispatch(notify.error({ message: "Selected file is not an image. Profile picture will not be updated." }));
			return;
		}
	};

	const handleSubmit = async (e, values, actions) => {
		try {
			e.preventDefault();
			setIsLoading(true);
			const postData = new FormData();
			postData.append('bio', bio);
			postData.append('is_publicly_searchable', isPubliclySearchable);
			postData.append('profile_picture', selectedFile);
			const savedStatus = await dispatch(updateProfile(postData));
			if (savedStatus) {
				setIsLoading(false);
				updateSessionDataInLocalStorage('is_publicly_searchable', isPubliclySearchable);
				//Reload the current page to reflect change in the top title bar
				//Here we are waiting 1 second after showing the notification message
				//Delay Starts
				const delay = ms => new Promise(res => setTimeout(res, ms));
				await delay(1000);
				// //Delay Ends
				window.location.reload();
			}
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	if (!Auth.isAuthenticated()) {
		navigate("/login");
	}

	return (
		<>
			<Container fluid>
				<Card>
					<LoadingOverlay isLoading={isLoading} />
					<Card.Body>
						<Row>
							<Col md={12} className="profile-edit-page-heading-col">
								<Image src={process.env.REACT_APP_MEDIA_URL + profileData.profile_picture} roundedCircle width="60" height="60" />
								<h3 className="ms-3">My Profile</h3>
							</Col>
						</Row>
						<hr />
						<Row>
							{displayOnlyFields.map(({ id, label, value }) => (
								<Col md={4} key={id}>
									<div className="mt-2">
										<InputGroup size="md" className="mb-3">
											<InputGroup.Text id={id}><b>{label}</b></InputGroup.Text>
											<Form.Control
												placeholder={id}
												aria-label={id}
												aria-describedby={id}
												value={value}
												disabled
											/>
										</InputGroup>
									</div>
								</Col>
							))}
						</Row>
						<hr />
						<Row>
							<form encType="multipart/form-data" onSubmit={handleSubmit}>
								<Col md={12}>
									<div className="mt-2">
										<InputGroup>
											<InputGroup.Text><strong>Personal bio</strong></InputGroup.Text>
											<Form.Control as="textarea" aria-label="Personal bio" defaultValue={profileData.bio || ""} onChange={e => setBio(e.target.value)} />
										</InputGroup>
									</div>
								</Col>
								<Col md={3}>
									<div className="mt-3">
										<InputGroup>
											<InputGroup.Text><strong>Allow public profile search</strong></InputGroup.Text>
											<Form.Select
												name="is_publicly_searchable"
												aria-label="Default select example"
												value={profileData.is_publicly_searchable}
												onChange={(e) => {
													setIsPubliclySearchable(e.target.value);
													setProfileData({ ...profileData, is_publicly_searchable: e.target.value });
												}}
											>
												<option value="0">No</option>
												<option value="1">Yes</option>
											</Form.Select>
										</InputGroup>
									</div>
								</Col>
								<Col md={3}>
									<div className="mt-3">
										<InputGroup>
											<InputGroup.Text className="me-1"><strong>Choose new profile picture</strong></InputGroup.Text>
											<Form.Control type="file" onChange={handleFileChange} accept="image/*" />
										</InputGroup>
									</div>
								</Col>
								<hr />
								<Col md={12}>
									<Button className="mt-2" type="submit" variant="outline-primary">
										<FontAwesomeIcon icon={faFloppyDisk} /> Update Profile
									</Button>
								</Col>
							</form>
						</Row>
					</Card.Body>
				</Card>
			</Container>

		</>
	)
}
export default Profile;