import React, { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Card from 'react-bootstrap/Card';
import { getUsersPublicResearchActivities } from '../../../../redux/userManagement/userManagementActions';
import FrontLoadingOverlay from '../../layout/FrontLoadingOverLay';
import parse from 'html-react-parser';

function UserProfileResearchOutput(props) {
	const params = useParams();
	const dispatch = useDispatch();
	const [userSlug, setUserSlug] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [userResearchOutputs, setUserResearchOutputs] = useState([]);

	//Setting userSlug on param changes, 
	useEffect(() => {
		const performUrlParamChanges = () => {
			const userSlug = (params && 'user_slug' in params) ? params.user_slug : '';
			setUserSlug(userSlug);
		};

		performUrlParamChanges();
	}, [params]);



	useEffect(() => {
		if (userSlug && userSlug !== '') {
			setIsLoading(true);
			let postData = {
				'slug': userSlug
			};
			dispatch(getUsersPublicResearchActivities(postData)).then((userResearchOutputs) => {
				if (!userResearchOutputs || userResearchOutputs.length === 0) {
					// navigate("/url_not_found");
				}
				setUserResearchOutputs(userResearchOutputs);
				setIsLoading(false);
			});
		}
	}, [userSlug]);

	return (
		<>
			<Card className="mt-3" style={{ background: ' #4c566a', color: '#fff' }} >
				<Card.Body style={{ padding: '0.4em' }}>
					<Card.Title style={{ marginBottom: 'unset' }}>Research outputs</Card.Title>
				</Card.Body>
			</Card>
			<Card className="mt-3 px-2" style={{ background: ' #4c566a' }}>
				<FrontLoadingOverlay isLoading={isLoading} />
				<Card.Body style={{ padding: '0.4em' }}>
					{userResearchOutputs.map((singleResearch, key) => (
						<Card className="mt-2" key={key}>
							<Card.Body>
								<Link replace={true} to={`/research_activity/` + singleResearch.slug} title="View research activity detail">
									<h5>{singleResearch.research_title}</h5>
								</Link>
								<hr />
								<div className="long-description-container-wrapper">
									{(singleResearch.description && singleResearch.description !== '') ? parse(singleResearch.description) : ''}
								</div>
								<hr />
								<ul>
									<li><strong>Research completed on : {singleResearch.research_date}</strong></li>
								</ul>
							</Card.Body>
						</Card>
					))}
				</Card.Body>
			</Card >
		</>
	);
}
export default UserProfileResearchOutput;