import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';

import Auth from '../../../helpers/Auth';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { saveHierarchy, fetchSingleHierarchy } from '../../../redux/hierarchyManagement/hierarchyManagementActions';
import { getFormType, getUniqueIdToUpdate, mapDBDataWithFormData, getInitialDataFromDBMapper } from '../../../helpers/CommonHelper';
import LoadingOverlay from '../layout/LoadingOverlay';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import FormSelect from 'react-bootstrap/FormSelect';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";


const validationSchema = Yup.object().shape({
	hierarchy_name: Yup.string().required('Hierarchy name is required'),
	level: Yup.string().required('Hierarchy level is required'),
});

function ModifyHierarchyRecord() {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	let location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [formType, setFormType] = useState('');
	const [updateId, setUpdateId] = useState('');
	const [formFields, setFormFields] = useState({
		hierarchy_name: '',
		level: '',
	});

	// Runs ONCE after initial rendering and after every rendering ONLY IF `location` changes
	useEffect(() => {
		performLocationChanges();
	}, [location]);

	const performLocationChanges = async () => {
		const pathName = location.pathname;
		const pathArray = pathName.split('/');
		//Set what type of form is this Insert or Update
		getFormType(pathArray).then((formType) => setFormType(formType));
		//Set update id to updateId if form is update otherwise set  ''
		getUniqueIdToUpdate(pathArray).then((updateId) => setUpdateId(updateId));
	}

	// Runs ONCE after initial rendering and after every rendering ONLY IF `formType, updateId` changes
	useEffect(() => {
		if (formType === 'UPDATE' && updateId !== '') {
			let formData = new FormData();
			formData.append('hierarchyId', updateId);
			setIsLoading(true);
			dispatch(fetchSingleHierarchy(formData)).then((singleDataArr) => {
				const singleData = singleDataArr[0];
				setFormFields(singleData);
				setIsLoading(false);
			});
		}
	}, [formType, updateId]);

	const handleSubmit = async (values, actions) => {
		try {
			setIsLoading(true);
			//Removing is_deleted properties from values
			delete values.is_deleted;
			if (formType === "UPDATE" && updateId !== "") {
				values.id = updateId;
			}
			const savedStatus = await dispatch(saveHierarchy(values));
			if (savedStatus) {
				navigate("/dashboard/hierarchy_management");
			}
			setIsLoading(false);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	const isInvalid = (errors, touched, fieldName) => errors[fieldName] && touched[fieldName];
	if (!Auth.isAuthenticated()) {
		navigate("/login");
	}

	return (
		<>
			<Card className="shadow-5 m-3">
				<LoadingOverlay isLoading={isLoading} />
				<Card.Body>
					<Formik
						initialValues={formFields}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
						enableReinitialize={true}
					>
						{({ errors, touched, values, handleChange }) => (
							<Form>
								<Row>
									<Col md='6'>
										<>
											<Field name="hierarchy_name">
												{({ field }) => (
													<FloatingLabel
														controlId="floatingInput"
														label="Hierarchy name"
														className="mb-3"
													>
														<FormControl
															type="text"
															placeholder="Hierarchy name"
															{...field}
															className={isInvalid(errors, touched, 'hierarchy_name') ? 'mt-1 is-invalid' : 'mt-1'}
														/>
													</FloatingLabel>
												)}
											</Field>
											{isInvalid(errors, touched, 'hierarchy_name') && (
												<div className="invalid-feedback">{errors.hierarchy_name}</div>
											)}
										</>
									</Col>
									<Col md='6'>
										<>
											<Field name="level">
												{({ field }) => (
													<FloatingLabel
														controlId="floatingInput"
														label="Level"
														className="mb-3"
													>
														<FormControl
															type="number"
															// min="1"
															placeholder="Level"
															{...field}
															className={isInvalid(errors, touched, 'level') ? 'mt-1 is-invalid' : 'mt-1'}
														/>
													</FloatingLabel>
												)}
											</Field>
											{isInvalid(errors, touched, 'level') && (
												<div className="invalid-feedback">{errors.level}</div>
											)}
										</>
									</Col>
									<Col md='12'>
										<>
											<Button className="mt-2" type="submit" variant="outline-primary">
												<FontAwesomeIcon icon={faFloppyDisk} /> Submit
											</Button>
										</>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</Card.Body>
			</Card>
		</>
	)
}

export default ModifyHierarchyRecord;
