import React, { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { getUserPersonalDetail } from '../../../../redux/userManagement/userManagementActions';
import FrontLoadingOverlay from '../../layout/FrontLoadingOverLay';


const UserProfilePersonalDetails = (props) => {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [userSlug, setUserSlug] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [userPersonalDetail, setUserPersonalDetail] = useState({});


	//Setting userSlug on param changes, 
	useEffect(() => {
		performUrlParamChanges();
	}, [params]);

	const performUrlParamChanges = () => {
		const userSlug = (params && 'user_slug' in params) ? params.user_slug : '';
		setUserSlug(userSlug);
	};

	useEffect(() => {
		if (userSlug && userSlug != '') {
			setIsLoading(true);
			let postData = {
				'slug': userSlug
			};
			dispatch(getUserPersonalDetail(postData)).then((userPersonalDetail) => {
				if (!userPersonalDetail || Object.keys(userPersonalDetail).length === 0) {
					navigate("/url_not_found");
				}
				setUserPersonalDetail(userPersonalDetail);
				setIsLoading(false);
			});
		}
	}, [userSlug]);

	return (
		<>
			<Card className="mt-3">
				<FrontLoadingOverlay isLoading={isLoading} />
				<Card.Body>
					<Container>
						<Row>
							<Col md={12}>
								<center><Image src={process.env.REACT_APP_BACKENDAPI_URL + userPersonalDetail.profile_picture} width="125" height="125" roundedCircle /></center>
							</Col>
						</Row>
						<Row className="mt-2">
							<Col md={12}>
								<center><h3>{userPersonalDetail.first_name} {userPersonalDetail.middle_name} {userPersonalDetail.last_name}</h3></center>
							</Col>
						</Row>
						<hr />
						<Row className="mt-2">
							<Col md={12}>
								<p><FontAwesomeIcon icon={faEnvelope} /> {userPersonalDetail.email} </p>
							</Col>
						</Row>
						<hr />
					</Container>
				</Card.Body>

			</Card>


		</>
	)

}
export default UserProfilePersonalDetails;