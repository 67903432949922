import React from 'react';
import { Routes, Route } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TopNavigation from './layout/TopNavigation';
import ProfileSearch from './pages/ProfileSearch';
import UserProfile from './pages/UserProfile';
import ResearchDetail from './pages/ResearchDetail';
import PageNotFound from '../common/PageNotFound';
import Login from './Login';

function Home() {
	return (
		<>
			<Container fluid style={{ background: 'linear-gradient(90deg, #141e30, #243b55)', minHeight: '100vh' }}>
				<Row>
					<Col md={12} style={{ "padding": "unset", position: "sticky", top: '0', zIndex: 1000 }}>
						<TopNavigation />
					</Col>
					<Col md={12}>
						<Routes>
							<Route path="/" element={<ProfileSearch />} />
							<Route path="/login" element={<Login />} />
							<Route path="/experts/:user_slug/*" element={<UserProfile />} />
							<Route path="/research_activity/:research_activity_slug/*" element={<ResearchDetail />} />
							<Route path="/url_not_found" element={<PageNotFound />} />
							<Route path="/*" element={<PageNotFound />} />
						</Routes>
					</Col>
				</Row>
			</Container >
		</>
	);
}

export default Home;