import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../404.css';

function PageNotFound(props) {
	return (
		<>
			<Container className="mt-3" fluid >
				<Row>
					<Col md={12}>
						<Card>
							<Card.Body>
								<div className="wrapper-404">
									<h1 className="heading-404">4<span><i className="fas fa-ghost"></i></span>4</h1>
									<h2 className="subheading-404">Error: 404 page not found</h2>
									<p className="text-404">Sorry, the page you're looking for cannot be accessed</p>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}
export default PageNotFound;