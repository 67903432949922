import './main.css';
import { Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import moment from 'moment-timezone';

import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from './redux/store';

import ProtectedRoute from './helpers/ProtectedRoute';
import Home from './components/frontend/Home';
import DashboardLayout from "./components/backend/layout/DashboardLayout";

function App() {
	moment.tz.setDefault('Australia/Brisbane');

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<div className="App">
					<Routes>
						<Route path="/*" element={<Home />} />
						<Route element={<ProtectedRoute />}>
							<Route exact path="/dashboard/*" element={<DashboardLayout />} />
						</Route>
					</Routes>
				</div>
			</PersistGate>

		</Provider >
	);
}

export default App;
