import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import parse from 'html-react-parser';
import Auth from '../../../../helpers/Auth';
import { getProposalStatusText } from '../../../../helpers/CommonHelper';

function ResearcherProposalFeedback(props) {
	const [researchId, setResearchId] = useState('');
	const researchDetail = useSelector(state => state.researchActivities.exploreSingleResearchDetail); //Same as mapStateToProps

	useEffect(() => {
		setResearchId(props.research_id);
	}, [props]);

	const proposalFeedback = researchDetail.research_proposal_feedback || '-';
	const proposalAnalysedOn = researchDetail.research_proposal_analysed_on || '-';

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />;
	}

	return (
		<Card className="mt-2">
			<Card.Body className="custom-card-body">
				<div>
					<strong>Proposal status: </strong>
					<div>
						<Badge pill bg="primary">{getProposalStatusText(researchDetail.research_proposal_status)}</Badge>
					</div>
				</div>
				<div>
					<strong>Proposal feedback: </strong>
					<div>{parse(proposalFeedback)}</div>
				</div>
				<div>
					<strong>Feedback date: </strong>
					<div>{proposalAnalysedOn}</div>
				</div>
			</Card.Body>
		</Card>
	);
}

export default ResearcherProposalFeedback;
