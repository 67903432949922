import React, { useState, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import { Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import parse from 'html-react-parser';
import Auth from '../../../helpers/Auth';

import ProposalResearchCard from './ProposalResearchCard';

function ProposalView(props) {
	const [researchDetail, setResearchDetail] = useState({});

	const descriptionRef = useRef(null);
	const purposeRef = useRef(null);
	const methodologyRef = useRef(null);
	const associationRef = useRef(null);
	const toolsRef = useRef(null);
	const toolsSourceRef = useRef(null);
	const outcomesRef = useRef(null);
	const dataAnalysisRef = useRef(null);
	const interpretationRef = useRef(null);

	const scrollToRef = (ref) => {
		if (ref.current) {
			window.scrollTo(0, ref.current.offsetTop - 120);
		}
	};

	//Run once and everytime after props changes
	useEffect(() => {
		setResearchDetail(props.research_detail);
	}, [props]);

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}

	return (
		<>
			<Accordion className="mt-2">
				<Accordion.Item eventKey="0">
					<Accordion.Header ><strong>Submitted research proposal (Click to expand / collapse)</strong></Accordion.Header>
					<Accordion.Body className="custom-card-body">
						<Row>
							<Col md={12}>
								<Card className="" >
									<Card.Body style={{ padding: '0.4em', fontWeight: 'bold', color: '#fff' }}>
										<Nav defaultActiveKey="/home" as="ul">
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(descriptionRef)}>Description</Nav.Link></Nav.Item>
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(purposeRef)}>Purpose</Nav.Link></Nav.Item>
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(methodologyRef)}>Methodology</Nav.Link></Nav.Item>
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(associationRef)}>Project Association</Nav.Link></Nav.Item>
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(toolsRef)}>Materials Used</Nav.Link></Nav.Item>
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(toolsSourceRef)}>Materials Source</Nav.Link></Nav.Item>
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(outcomesRef)}>Project Outcomes</Nav.Link></Nav.Item>
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(dataAnalysisRef)}>Data Analysis</Nav.Link></Nav.Item>
											<Nav.Item as="li"> <Nav.Link onClick={() => scrollToRef(interpretationRef)}>Interpretation Analysis</Nav.Link></Nav.Item>
										</Nav>
									</Card.Body>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col md={2}>
								<Card className="mt-3">
									<Card.Body>
										<Container>
											<Row>
												<Col md={12} className="mt-1">
													<h6>Author(s)</h6>
													<ul>
														<li>
															<p style={{ marginBottom: '0px' }}><strong>{researchDetail.research_author}</strong></p>
														</li>
													</ul>
												</Col>
												<hr />
												<Col md={12} className="mt-1">
													<h6>Research date</h6>
													<ul>
														<li>
															<p style={{ marginBottom: '0px' }}><strong>{researchDetail.research_date}</strong></p>
														</li>
													</ul>
												</Col>
												<hr />
												<Col md={12} className="mt-1">
													<h6>Authorship attribution</h6>
													<div>{(researchDetail.research_authorship_attribution && researchDetail.research_authorship_attribution != '') ? parse(researchDetail.research_authorship_attribution) : 'NA'}</div>
												</Col>
											</Row>
										</Container>
									</Card.Body>
								</Card>
							</Col>
							<Col md={10}>
								<ProposalResearchCard title="Description" body={researchDetail.description} ref={descriptionRef} />
								<ProposalResearchCard title="Purpose" body={researchDetail.research_purpose} ref={purposeRef} />
								<ProposalResearchCard title="Methodology" body={researchDetail.research_methodology} ref={methodologyRef} />
								<ProposalResearchCard title="Project Association" body={researchDetail.research_project_association} ref={associationRef} />
								<ProposalResearchCard title="Materials Used" body={researchDetail.research_tool} ref={toolsRef} />
								<ProposalResearchCard title="Materials Source" body={researchDetail.research_tool_source} ref={toolsSourceRef} />
								<ProposalResearchCard title="Project Outcomes" body={researchDetail.research_project_outcomes} ref={outcomesRef} />
								<ProposalResearchCard title="Data Analysis" body={researchDetail.research_data_analysis} ref={dataAnalysisRef} />
								<ProposalResearchCard title="Interpretation Analysis" body={researchDetail.research_interpreatation_analysis} ref={interpretationRef} />
							</Col>
						</Row>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>
	);
}
export default ProposalView;