import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';

import {
	exploreSingleResearchDetail,
	getProgressReports,
} from '../../../redux/researchActivities/researchActivitiesActions';
import * as researchActivityHelpers from '../../../redux/researchActivities/researchActivitiesActionHelpers';


import Auth from '../../../helpers/Auth';
import { getModificationFlags } from '../../../helpers/CommonHelper';

import ProposalView from './ProposalView';
import EvaluatorProposalFeedback from './evaluator/EvaluatorProposalFeedback';
import ResearcherProposalFeedback from './researcher/ResearcherProposalFeedback';
import EvaluatorProgressReport from './evaluator/EvaluatorProgressReport';
import ResearcherProgressReport from './researcher/ResearcherProgressReport';
import ResearcherFinalReport from './researcher/ResearcherFinalReport';
import EvaluatorFinalReport from './evaluator/EvaluatorFinalReport';

function SingleResearchDetail(props) {
	const dispatch = useDispatch();

	const [activeKey, setActiveKey] = useState('research_proposal_tab');
	const [researchId, setResearchId] = useState('');
	const [viewerRole, setViewerRole] = useState('');
	const researchDetail = useSelector(state => state.researchActivities.exploreSingleResearchDetail); //Same as mapStateToProps
	const allProgressReports = useSelector(state => state.researchActivities.progressReports); //Same as mapStateToProps

	useEffect(() => {
		setResearchId(props.research_id);
		setViewerRole(props.viewer_role);
	}, [props]);

	const handleTabSelect = (key) => {
		setActiveKey(key);
	};

	useEffect(() => {
		if (researchId && researchId !== '') {
			const postData = {
				researchId: researchId,
			};

			dispatch(exploreSingleResearchDetail(postData));
			dispatch(getProgressReports(postData));
		}
	}, [researchId]);

	useEffect(() => {
		const proposalStatus = researchDetail.research_proposal_status;
		const finalReportStatus = researchDetail.final_report_status;
		const numOfProgressReports = allProgressReports.length;
		const numOfSubmittedProgressReports = allProgressReports.filter(report => report.is_submitted === '1').length;
		const modificationFlags = getModificationFlags(proposalStatus, finalReportStatus, numOfProgressReports, numOfSubmittedProgressReports);

		dispatch(researchActivityHelpers.setResearchModificationFlags(modificationFlags));
	}, [researchDetail, allProgressReports]);

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />;
	}

	const renderProposalFeedback = () => {
		if (viewerRole === 'evaluator') {
			return <EvaluatorProposalFeedback research_id={researchId} />;
		} else if (viewerRole === 'researcher') {
			return <ResearcherProposalFeedback research_id={researchId} />;
		} else {
			return <>ERROR !! INVALID VIEWER ROLE</>;
		}
	};

	const renderProgressReport = () => {
		if (viewerRole === 'evaluator') {
			return <EvaluatorProgressReport research_id={researchId} />;
		} else if (viewerRole === 'researcher') {
			return <ResearcherProgressReport research_id={researchId} />;
		} else {
			return <>ERROR !! INVALID VIEWER ROLE</>;
		}
	};

	const renderFinalReport = () => {
		if (viewerRole === 'evaluator') {
			return (
				<EvaluatorFinalReport
					research_id={researchId}
					research_detail={researchDetail}
				/>
			);
		} else if (viewerRole === 'researcher') {
			return (
				<ResearcherFinalReport
					research_id={researchId}
					research_detail={researchDetail}
				/>
			);
		} else {
			return <>ERROR !! INVALID VIEWER ROLE</>;
		}
	};

	return (
		<>
			<div>
				<h5>
					<strong>
						<i>Research title:</i>{' '}
						{researchDetail && Object.keys(researchDetail).length > 0 && 'research_title' in researchDetail
							? researchDetail.research_title
							: ''}
					</strong>
				</h5>
			</div>
			<hr />
			<Tab.Container activeKey={activeKey} onSelect={handleTabSelect}>
				<Nav fill variant="tabs">
					<Nav.Item>
						<Nav.Link eventKey="research_proposal_tab">
							<strong>Research proposal</strong>
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="research_progress_reports">
							<strong>Progress reports</strong>
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link eventKey="final_report">
							<strong>Final report</strong>
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<Tab.Content>
					{activeKey === 'research_proposal_tab' && (
						<Tab.Pane eventKey="research_proposal_tab">
							{renderProposalFeedback()}
							<ProposalView research_id={researchId} research_detail={researchDetail} />
						</Tab.Pane>
					)}
					{activeKey === 'research_progress_reports' && (
						<Tab.Pane eventKey="research_progress_reports">
							{renderProgressReport()}
						</Tab.Pane>
					)}
					{activeKey === 'final_report' && (
						<Tab.Pane eventKey="final_report">
							{renderFinalReport()}
						</Tab.Pane>
					)}
				</Tab.Content>
			</Tab.Container>
		</>
	);
}

export default SingleResearchDetail;
