import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Auth from '../../../helpers/Auth';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { saveUser, fetchSingleUser } from '../../../redux/userManagement/userManagementActions';
import { getAllHierarchies } from '../../../redux/hierarchyManagement/hierarchyManagementActions';
import { getFormType, getUniqueIdToUpdate } from '../../../helpers/CommonHelper';
import LoadingOverlay from '../layout/LoadingOverlay';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import FormSelect from 'react-bootstrap/FormSelect';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import * as notify from '../../../redux/studentNotification/notificationAction';


const validationSchema = Yup.object().shape({
	first_name: Yup.string().required('First name is required'),
	last_name: Yup.string().required('Last name is required'),
	hierarchy_id: Yup.number().required('Hierarchy is required'),
	email: Yup.string().email().required('Email is required'),
	username: Yup.string().required('Username is required'),
	status: Yup.number().required('Status is required'),
});

function ModifyUserRecord() {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	let location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [formType, setFormType] = useState('');
	const [updateId, setUpdateId] = useState('');
	const [formFields, setFormFields] = useState({
		first_name: '',
		middle_name: '',
		last_name: '',
		hierarchy_id: '',
		email: '',
		username: '',
		password: '',
		status: '',
	});
	const [allHierarchies, setAllHierarchies] = useState([]);
	const [allUserStatus, setUserStatus] = useState([
		{ label: 'Active', value: '1' },
		{ label: 'In-Active', value: '0' },
	]);

	// Runs ONCE after initial rendering and after every rendering ONLY IF `location` changes
	useEffect(() => {
		const pathName = location.pathname;
		const pathArray = pathName.split('/');
		//Set what type of form is this Insert or Update
		getFormType(pathArray).then((formType) => setFormType(formType));
		//Set update id to updateId if form is update otherwise set  ''
		getUniqueIdToUpdate(pathArray).then((updateId) => setUpdateId(updateId));
		//Get all available hierarchies
		dispatch(getAllHierarchies()).then((allHierarchies) => {
			setAllHierarchies(allHierarchies);
		});
	}, [location]);

	// Runs ONCE after initial rendering and after every rendering ONLY IF `formType, updateId` changes
	useEffect(() => {
		if (formType === 'UPDATE' && updateId !== '') {
			let formData = new FormData();
			formData.append('userId', updateId);
			setIsLoading(true);
			dispatch(fetchSingleUser(formData)).then((singleUserData) => {
				const singleData = singleUserData[0];
				singleData.password = '';
				setFormFields(singleData);
				setIsLoading(false);
			});
		}
	}, [formType, updateId]);

	const handleSubmit = async (values, actions) => {
		try {
			setIsLoading(true);
			//Removing is_deleted properties from values
			delete values.is_deleted;
			if (formType !== 'UPDATE') {
				//Check if the passord exists or not
				if (values.password === '') {
					dispatch(notify.error({ message: 'Password is required' }));
					setIsLoading(false);
					return;
				}
			}
			if (formType === "UPDATE" && updateId !== "") {
				values.id = updateId;
			}
			const savedStatus = await dispatch(saveUser(values));
			if (savedStatus) {
				navigate("/dashboard/user_management");
			}
			setIsLoading(false);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	const isInvalid = (errors, touched, fieldName) => errors[fieldName] && touched[fieldName];
	if (!Auth.isAuthenticated()) {
		navigate("/login");
	}

	return (
		<>
			<Card className="shadow-5 m-3">
				<LoadingOverlay isLoading={isLoading} />
				<Card.Body>
					<Formik
						initialValues={formFields}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
						enableReinitialize={true}
					>
						{({ errors, touched, values, handleChange }) => (
							<Form>
								<Row>
									<Col md='6'>
										<>
											<Field name="first_name">
												{({ field }) => (
													<FloatingLabel
														controlId="floatingInput"
														label="First name"
														className="mb-3"
													>
														<FormControl
															type="text"
															placeholder="First name"
															{...field}
															className={isInvalid(errors, touched, 'first_name') ? 'mt-1 is-invalid' : 'mt-1'}
														/>
													</FloatingLabel>
												)}
											</Field>
											{isInvalid(errors, touched, 'first_name') && (
												<div className="invalid-feedback">{errors.first_name}</div>
											)}
										</>
										<>
											<Field name="middle_name">
												{({ field }) => (
													<FloatingLabel
														controlId="floatingInput"
														label="Middle name"
														className="mb-3"
													>
														<FormControl
															size="sm"
															type="text"
															placeholder="Middle name"
															{...field}
															className={isInvalid(errors, touched, 'middle_name') ? 'mt-3 is-invalid' : 'mt-3'}
														/>
													</FloatingLabel>
												)}
											</Field>
											{isInvalid(errors, touched, 'middle_name') && (
												<div className="invalid-feedback">{errors.middle_name}</div>
											)}
										</>
										<>
											<Field name="email">
												{({ field }) => (
													<FloatingLabel
														controlId="floatingInput"
														label="Email"
														className="mb-3"
													>
														<FormControl
															size="sm"
															type="email"
															placeholder="Email"
															{...field}
															className={isInvalid(errors, touched, 'email') ? 'mt-3 is-invalid' : 'mt-3'}
														/>
													</FloatingLabel>
												)}
											</Field>
											{isInvalid(errors, touched, 'email') && (
												<div className="invalid-feedback">{errors.email}</div>
											)}
										</>
										<>
											<Field name="password">
												{({ field }) => (
													<FloatingLabel
														controlId="floatingInput"
														label="Password"
														className=""
													>
														<FormControl
															size="sm"
															type="text"
															placeholder="Password"
															{...field}
															className={isInvalid(errors, touched, 'password') ? 'mt-3 is-invalid' : 'mt-3'}
														/>
													</FloatingLabel>
												)}
											</Field>
											{isInvalid(errors, touched, 'password') && (
												<div className="invalid-feedback">{errors.password}</div>
											)}
											{(formType === 'UPDATE') ? <><p style={{ margin: 'unset', color: '#0d6efd' }}>Please provide password only if you are willing to change the password. Leave empty if you want to use old password</p></> : null}
										</>
									</Col>
									<Col md='6'>
										<>
											<Field name="last_name">
												{({ field }) => (
													<FloatingLabel
														controlId="floatingInput"
														label="Last name"
														className="mb-3"
													>
														<FormControl
															type="text"
															placeholder="Last name"
															{...field}
															className={isInvalid(errors, touched, 'last_name') ? 'mt-1 is-invalid' : 'mt-1'}
														/>
													</FloatingLabel>
												)}
											</Field>
											{isInvalid(errors, touched, 'last_name') && (
												<div className="invalid-feedback">{errors.last_name}</div>
											)}
										</>
										<>
											<Field name="hierarchy_id">
												{({ field }) => (
													<FloatingLabel
														controlId="floatingSelect"
														label="Hierarchy level"
														className="mb-3">

														<FormSelect
															{...field}
															value={values.hierarchy_id || ""}
															onChange={handleChange}
															className={isInvalid(errors, touched, "hierarchy_id") ? "mt-1 is-invalid" : "mt-1"}
														>
															<option value="">Select Hierarchy level</option>
															{allHierarchies.map((option) => (
																<option key={option.id} value={option.id}>
																	{option.hierarchy_name}
																</option>
															))}
														</FormSelect>
													</FloatingLabel>
												)}
											</Field>
											{isInvalid(errors, touched, 'hierarchy_id') && (
												<div className="invalid-feedback mt-1">{errors.hierarchy_id}</div>
											)}
										</>
										<>
											<Field name="username">
												{({ field }) => (
													<FloatingLabel
														controlId="floatingInput"
														label="Username"
														className="mb-3"
													>
														<FormControl
															type="text"
															placeholder="Username"
															{...field}
															className={isInvalid(errors, touched, 'username') ? 'mt-1 is-invalid' : 'mt-1'}
														/>
													</FloatingLabel>
												)}
											</Field>
											{isInvalid(errors, touched, 'username') && (
												<div className="invalid-feedback">{errors.username}</div>
											)}
										</>
										<>
											<Field name="status">
												{({ field }) => (
													<FloatingLabel
														controlId="floatingSelect"
														label="Status"
														className="mb-3">

														<FormSelect
															{...field}
															value={values.status}
															onChange={handleChange}
															className={isInvalid(errors, touched, "status") ? "mt-1 is-invalid" : "mt-1"}
														>
															<option value="">Select status</option>
															{allUserStatus.map((option) => (
																<option key={option.value} value={option.value}>
																	{option.label}
																</option>
															))}
														</FormSelect>
													</FloatingLabel>
												)}
											</Field>
											{isInvalid(errors, touched, 'status') && (
												<div className="invalid-feedback mt-1">{errors.status}</div>
											)}
										</>
									</Col>
									<Col md='12'>
										<>
											<Button className="mt-2" type="submit" variant="outline-primary">
												<FontAwesomeIcon icon={faFloppyDisk} /> Save and continue
											</Button>
										</>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</Card.Body>
			</Card >
		</>
	)
}

export default ModifyUserRecord;
