import { getAPIUrl, customAxios, rectifyAxiosErrors } from '../../services/Services';
import { logData, extractMessageFromResponse } from '../../helpers/CommonHelper';
import * as notify from '../studentNotification/notificationAction';
const axiosInstance = customAxios();

export const getAllHierarchies = () => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('get_all_hierarchies');
			const response = await axiosInstance.get(callUrl);
			const responseData = response.data;
			if (responseData.status) {
				const allData = responseData.data;
				resolve(allData);
			} else {
				dispatch(notify.error({ message: "Error while getting all hierarchy list" }));
				resolve([]);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve([]);
		}
	});
};

export const saveHierarchy = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('save_hierarchy');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const extractedMessage = await extractMessageFromResponse(responseData);
				dispatch(notify[extractedMessage.messageType]({ message: extractedMessage.message }));
				resolve(true);
			} else {
				const errorMessage = responseData.msg;
				dispatch(notify.error({ message: errorMessage }));
				resolve(false);
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve(false);
		}
	});
};

export const fetchSingleHierarchy = (postData) => async (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			const callUrl = getAPIUrl('get_single_hierarchy');
			const response = await axiosInstance.post(callUrl, postData);
			const responseData = response.data;
			if (responseData.status) {
				const singleHierarchyDetail = responseData.data;
				resolve(singleHierarchyDetail);
			} else {
				dispatch(notify.error({ message: "Error while getting all users list" }));
				resolve({});
			}
		} catch (error) {
			console.log(error);
			const allErrorMessages = await rectifyAxiosErrors(error);
			dispatch(notify.error({ message: allErrorMessages }));
			resolve({});
		}
	});
};