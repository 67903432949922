import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Badge from 'react-bootstrap/Badge';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { getSessionData } from '../../../helpers/CommonHelper';

import Auth from '../../../helpers/Auth';
function TitleBar(props) {
	let navigate = useNavigate();
	const [publclyVisible, setPubliclyVisible] = useState(getSessionData('is_publicly_searchable'));
	//Logout Method
	const performLogout = () => {
		Auth.logout().then(() => {
			navigate("/login");
		});
	};

	//Check Authentication
	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}
	return (
		<Container className="px-0" fluid style={{ position: 'sticky', top: '0px', zIndex: '1', backgroundColor: '#4c566a' }}>
			<Navbar expand="lg">
				<Container fluid>
					<Navbar.Brand className="backend-top-navbar">
						<Link replace={true} to="/">
							<strong>ANP Record of Research</strong>
						</Link>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" style={{ color: '#fff' }}>
						<FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faCaretDown} />
					</Navbar.Toggle>
					<Navbar.Collapse className="justify-content-end">
						<Nav className="">
							<Nav className='mx-2 backend-title-nav' style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
								<Link replace={true} to="/dashboard/profile">
									Profile
								</Link>
							</Nav>
							<Navbar.Text style={{ color: '#b9d8ff' }}>
								| {(publclyVisible === '1') ? (<Badge pill bg="success">Publicly visible</Badge>) : (<Badge pill bg="danger">Publicly invisible</Badge>)} | <Badge pill bg="dark"> Signed in as:  {getSessionData('firstName')} {getSessionData('middleName')} {getSessionData('lastName')} </Badge> |
							</Navbar.Text>
							<Nav.Link onClick={() => performLogout()} style={{ color: '#fff' }}>Log out</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar >
		</Container >
	);
}
export default TitleBar;