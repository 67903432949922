import * as notificationActionType from './notificationActionType';
import { toast } from 'react-toastify';

export const success = (notificationData) => {
	return async dispatch => {
		dispatch({
			type: notificationActionType.NOTIFY_SUCCESS,
			message: notificationData.message,
		});
		toast.success(notificationData.message, { theme: "dark" });
	}
}

export const info = (notificationData) => {
	return async dispatch => {
		dispatch({
			type: notificationActionType.NOTIFY_INFO,
			message: notificationData.message,
		});
		toast.info(notificationData.message, { theme: "dark" });
	}
}
export const warn = (notificationData) => {
	return async dispatch => {
		dispatch({
			type: notificationActionType.NOTIFY_WARNING,
			message: notificationData.message,
		});
		toast.warn(notificationData.message, { theme: "dark" });
	}
}

export const error = (notificationData) => {
	return async dispatch => {
		dispatch({
			type: notificationActionType.NOTIFY_ERROR,
			message: notificationData.message,
		});
		toast.error(notificationData.message, { theme: "dark" });
	}
}

