import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';
import Auth from '../../../helpers/Auth';

function FinalReportView(props) {
	const [researchDetail, setResearchDetail] = useState({});

	useEffect(() => {
		setResearchDetail(props.research_detail);
	}, [props]);

	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />;
	}

	const hasFinalReport =
		researchDetail &&
		'final_report' in researchDetail &&
		researchDetail.final_report &&
		researchDetail.final_report !== '' &&
		researchDetail.final_report_status !== '0';

	const hasFinalReportFeedback =
		researchDetail &&
		'final_report_status' in researchDetail &&
		researchDetail.final_report_status === '3' &&
		'final_report_feedback' in researchDetail &&
		researchDetail.final_report_feedback &&
		researchDetail.final_report_feedback !== '';

	return (
		<Card className="mt-3">
			<Card.Header className="custom-card-header">
				<h5>
					<strong>Final research report</strong>
				</h5>
			</Card.Header>
			<Card.Body className="custom-card-body">
				{hasFinalReport ? (
					<Card className="mt-3">
						<Card.Header className="custom-card-header">
							<strong>Submitted final report</strong>
						</Card.Header>
						<Card.Body className="custom-card-body">
							<Row>
								<Col md={12}>
									<div className="border-1 p-1 m-1">
										<h6>
											<strong>Final report:</strong>
										</h6>
										<hr />
										{parse(researchDetail.final_report) || ''}
									</div>
								</Col>
							</Row>
							<hr />
							<Row>
								<Col md={6}>
									<div className="border-1 p-1 m-1">
										<h6>
											<strong>Next steps:</strong>
										</h6>
										<hr />
										{parse(researchDetail.research_next_steps) || ''}
									</div>
								</Col>
								<Col md={6}>
									<div className="border-1 p-1 m-1">
										<h6>
											<strong>Activity outcomes:</strong>
										</h6>
										<hr />
										{parse(researchDetail.research_activity_outcomes) || ''}
									</div>
								</Col>
								<Col md={6}>
									<div className="border-1 p-1 m-1">
										<h6>
											<strong>Activity outputs:</strong>
										</h6>
										<hr />
										{parse(researchDetail.research_activity_outputs) || ''}
									</div>
								</Col>
								<Col md={6}>
									<div className="border-1 p-1 m-1">
										<h6>
											<strong>Activity impacts:</strong>
										</h6>
										<hr />
										{parse(researchDetail.research_activity_impact) || ''}
									</div>
								</Col>
							</Row>
						</Card.Body>
						<Card.Footer>
							<Row>
								<Col md={12}>
									<strong>Submitted on: {researchDetail.final_report_submitted_on}</strong>
								</Col>
							</Row>
						</Card.Footer>
					</Card>
				) : null}
				{hasFinalReportFeedback ? (
					<Card className="mt-3">
						<Card.Header className="custom-card-header">
							<strong>Final report feedback</strong>
						</Card.Header>
						<Card.Body className="custom-card-body">
							{parse(researchDetail.final_report_feedback) || ''}
						</Card.Body>
						<Card.Footer>
							<Row>
								<Col md={12}>
									<strong>Analysed on: {researchDetail.final_report_analysed_on}</strong>
								</Col>
							</Row>
						</Card.Footer>
					</Card>
				) : null}
				{!hasFinalReport && !hasFinalReportFeedback ? (
					<Card.Body className="custom-card-body">
						<strong>Final research report not available</strong>
					</Card.Body>
				) : null}
			</Card.Body>
		</Card>
	);
}

export default FinalReportView;
