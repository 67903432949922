import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import Auth from '../../../helpers/Auth';
import LoadingOverlay from '../layout/LoadingOverlay';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faFilterCircleXmark, faCircleExclamation, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import DataTable from 'react-data-table-component';
import dataTableStyleConfig from '../../../config/DataTablesConfig.json';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { saveResearchActivity } from '../../../redux/researchActivities/researchActivitiesActions';
import { getUserStatusFlag } from '../../../helpers/CommonHelper'

const ExploreResearchActivitiesListing = (props) => {
	const dispatch = useDispatch();
	const dataFetchedRef = useRef(false); //using useRef to call only once from useeffect, workaround is to remove strictmode from index.js
	const [isLoading, setIsLoading] = useState(false);
	const [researchActivitiesList, setResearchActivitiesList] = useState([]);
	const [filterText, setFilterText] = React.useState('');
	//Filtering and pagination - STARTS
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const [tableColumns, setTableColumns] = useState([
		{
			name: 'Research title',
			selector: row => row.research_title,
			sortable: true,
		},
		{
			name: 'Author',
			selector: row => row.author_full_name + ' - ' + row.hierarchy_name,
			sortable: true,
		},
		{
			name: 'Proposal status',
			selector: row => row.proposal_status_text,
			sortable: true,
		},
		{
			name: 'Research status',
			selector: row => row.project_status_text,
			sortable: true,
		},
		{
			name: 'Action',
			sortable: false,
			cell: (row) =>
				<>
					<Link replace={true} to={`/dashboard/evaluate_research/view_single_research/${row.id}`} title="View Detail">
						<Button size="sm" variant='info'><FontAwesomeIcon icon={faEye} /> View</Button>
					</Link>
				</>,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	]);
	const filteredItems = researchActivitiesList.filter(
		item =>
			(item.research_title && item.research_title.toLowerCase().includes(filterText.toLowerCase())) ||
			(item.author_full_name && item.author_full_name.toLowerCase().includes(filterText.toLowerCase())) ||
			(item.hierarchy_name && item.hierarchy_name.toLowerCase().includes(filterText.toLowerCase()))
	);
	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<>
				<Form.Group controlId="table_search">
					<Form.Control type="text" placeholder="Search..."
						value={filterText || ""}
						onChange={e => setFilterText(e.target.value)} />
				</Form.Group>
				<Button size title="Reset filters" onClick={handleClear} variant='outline-secondary' style={{ border: 'unset' }}><FontAwesomeIcon icon={faFilterCircleXmark} /></Button >
			</>
		);
	}, [filterText, resetPaginationToggle]);
	//Filtering and pagination - ENDS

	//Run once and everytime after props.data_list changes
	useEffect(() => {
		setResearchActivitiesList(props.data_list);
	}, [props.data_list]);


	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}

	return (
		<div>
			<Card className="shadow-5">
				<Card.Body>
					<LoadingOverlay isLoading={isLoading} />
					{
						(() => {
							if (!researchActivitiesList || researchActivitiesList.length <= 0) {
								return (
									<div>
										<h5>No records currently available</h5>
									</div>
								)
							} else {
								return (
									<>
										<DataTable
											title="Research available to explore"
											pagination
											paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
											columns={tableColumns}
											data={filteredItems}
											fixedHeader
											subHeader
											subHeaderComponent={subHeaderComponentMemo}
											customStyles={dataTableStyleConfig.backendDataTableStyleConfig}
										/>
									</>
								)
							}
						})()
					}

				</Card.Body>
			</Card>
		</div>
	)
}

export default ExploreResearchActivitiesListing;