import React from 'react';
import Auth from '../../../helpers/Auth';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import TitleBar from '../layout/TitleBar';
import MenuBar from '../layout/MenuBar';

import { Routes, Route, Navigate, BrowserRouter, Switch } from "react-router-dom";
import Dashboard from '../dashboard/Dashboard';
import UserManagement from '../user_management/UserManagement';
import HierarchyManagement from '../hierarchy_management/HierarchyManagement';
import ResearchActivities from '../research_activities/ResearchActivities';
import EvaluateResearch from '../explore_research_activities/EvaluateResearch';
// import ExploreResearchActivitiesListing from '../explore_research_activities/ExploreResearchActivitiesListing';
import ExploreSingleResearch from '../research_activities/ExploreSingleResearch';
import Profile from '../profile/Profile';

import Card from 'react-bootstrap/Card';

import { getSessionData } from '../../../helpers/CommonHelper'
import PageNotFound from '../../common/PageNotFound';

const DashboardLayout = () => {
	if (!Auth.isAuthenticated()) {
		return <Navigate to="/login" replace />
	}

	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				toastClassName="dark-toast"
			/>
			<TitleBar />
			<MenuBar />
			<div className="main-content">
				<Routes>
					<Route exact path="/" element={<Dashboard />} />
					<Route exact path="/landing" element={<Dashboard />} />
					<Route exact path="/profile" element={<Profile />} />

					<Route exact path="/my_research_activities/*" element={<ResearchActivities />} />
					<Route exact path="/my_research_activities/view_single_research/:research_id/*" element={<ResearchActivities />} />

					<Route exact path="/evaluate_research/:research_type/*" element={<EvaluateResearch />} />
					<Route exact path="/evaluate_research/view_single_research/:research_id/*" element={<EvaluateResearch />} />

					<Route exact path="/user_management/*" element={<UserManagement />} />
					<Route exact path="/hierarchy_management/*" element={<HierarchyManagement />} />
					<Route path="*" element={<PageNotFound />} />
				</Routes>
			</div>
		</>
	);
}
export default DashboardLayout;