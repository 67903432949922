import React from 'react';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';


function SearchResults(props) {
	let searchResult = props.searchResult;
	return (
		<>
			{
				(() => {
					if (searchResult && Object.keys(searchResult).length !== 0) {
						return (
							<Col className="mt-4" md={12}>
								<Card className="shadow-5" style={{ background: 'transparent', color: '#FFF', width: '100%', border: 'none' }}>
									<Card.Header>
										<center><h3>ANP Experts Results</h3></center>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col md={3}>
											</Col>
											<Col md={6}>
												{
													(() => {
														if (searchResult.user_results && Object.keys(searchResult.user_results).length !== 0) {
															return (
																<>
																	{
																		searchResult.user_results.map((singleUserResult, key) => (
																			<Card key={key} className="shadow-5 mb-2" style={{ background: '#FFF', color: '#000', width: '100%', border: 'none' }}>
																				<Card.Body >
																					<Row>
																						<Col md={1}>
																							<Image width="70" height="70" src={process.env.REACT_APP_MEDIA_URL + singleUserResult.profile_picture} roundedCircle />
																						</Col>
																						<Col md={11}>
																							<Link replace={true} to={`/experts/${singleUserResult.slug}`} title="View expert">
																								<h5>{singleUserResult.first_name} {singleUserResult.middle_name} {singleUserResult.last_name}</h5>
																							</Link>
																							<div className="long-description-container-wrapper">
																								<p>
																									{singleUserResult.bio}
																								</p>
																							</div>
																						</Col>
																					</Row>
																				</Card.Body>
																			</Card>

																		))
																	}
																</>
															)
														} else {
															return (
																<center><h6 style={{ color: '#ff6a6a' }}>ANP experts not found, please try again by chaging the search parameters.</h6></center>
															)
														}
													})()
												}
											</Col>
											<Col md={3}>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						)
					}
				})()
			}
		</>
	);
}

export default SearchResults;